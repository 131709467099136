import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import { IconArrowLeft, IconPlus, IconPencil, IconBookmark } from '@tabler/icons-react'
import pluralize from 'pluralize'

import { closeModal, openModal } from '@/reducers/ui/ui.redux'
import { deleteSavedFilter } from '@/reducers/savedFilters/savedFilters.actions'
import { MY_TEAM_FILTER_TYPE } from '@/reducers/savedFilters/savedFilters.constants'
import { getMyTeamFilterAgents } from '@/reducers/savedFilters/savedFilters.helpers'
import { BasicModal } from '@/components/layout/modals/BasicModal'

import { SavedFilterForm } from './SavedFilterForm'
import { SavedFiltersList } from './SavedFiltersList'
import { AgentList } from './AgentList'

import './SavedFilters.scss'
import { SearchableDropdown } from '../dropdowns/SearchableDropdown'

const MANAGE_FILTERS_MODAL = 'savedFilterModal/manageFilters'
const CREATE_EDIT_FILTER_MODAL = 'savedFilterModal/createFilter'

export const SavedFilters = ({
  filterType,
  filterFormOptions = [],
  modalProps = {},
  noFiltersTitle,
  noFiltersMessage,
  noFiltersButtonLabel,
  selectedSavedFilterId,
  handleSelectSavedFilter,
  handleClearSavedFilter,
}) => {
  const dispatch = useDispatch()
  const { currentlyOpenModalId: modal } = useSelector((state) => state.ui)
  const { savedFilterList, loading: savedFiltersLoading } = useSelector(
    (state) => state.savedFilters
  )
  const [selectedFilter, setSelectedFilter] = useState(null)
  const savedFiltersByType = savedFilterList[filterType] || []
  const isMyTeamFilter = filterType === MY_TEAM_FILTER_TYPE
  const savedFilterOptions = savedFilterList[filterType].map((filter) => ({
    value: filter.uuid,
    label: filter.name,
    is_default: filter.is_default,
  }))

  const showNoFiltersMessage = isEmpty(savedFiltersByType)
  const myTeamAgents = getMyTeamFilterAgents(savedFiltersByType)

  const openManageFiltersModal = () => {
    if (isMyTeamFilter && !isEmpty(savedFiltersByType)) {
      setSelectedFilter(savedFiltersByType[0])
      dispatch(openModal(CREATE_EDIT_FILTER_MODAL))
    } else {
      dispatch(openModal(MANAGE_FILTERS_MODAL))
    }
  }

  const openCreateFilterModal = () => {
    setSelectedFilter(null)
    dispatch(openModal(CREATE_EDIT_FILTER_MODAL))
  }

  const actions = [
    {
      key: 'create-filters',
      fn: openCreateFilterModal,
      label: (
        <div className="flex-align-center small-gap">
          <IconPlus className="icon-svg status-brand" />
          Create New Filter
        </div>
      ),
    },
    {
      key: 'manage-filters',
      fn: openManageFiltersModal,
      label: (
        <div className="flex-align-center small-gap">
          <IconPencil className="icon-svg status-brand" />
          Manage My {isMyTeamFilter ? 'Team' : 'Filters'}
        </div>
      ),
    },
  ]

  const handleCloseModal = () => {
    dispatch(closeModal())
  }

  const handleBackButtonClick = () => {
    dispatch(openModal(MANAGE_FILTERS_MODAL))
  }

  const handleEditFilter = (filterId) => {
    const selectedFilter = savedFiltersByType.find((filter) => filter.uuid === filterId)

    setSelectedFilter(selectedFilter)
    dispatch(openModal(CREATE_EDIT_FILTER_MODAL))
  }

  const handleDeleteFilter = (filterId) => {
    dispatch(deleteSavedFilter(filterId, filterType))
  }

  return (
    <>
      <div data-testid="manage-filters" style={{ display: 'flex', gap: '0.75rem' }}>
        {!isEmpty(myTeamAgents) && isMyTeamFilter && <AgentList agents={myTeamAgents} />}
        {isMyTeamFilter ? (
          <Button secondary onClick={openManageFiltersModal}>
            My Team
          </Button>
        ) : (
          <SearchableDropdown
            dataTestId="saved-filters"
            buttonProps={{ content: 'Saved Filters', className: 'secondary' }}
            additionalIcon={<IconBookmark className="status-muted" />}
            additionalLabel={selectedSavedFilterId ? 'Filter: ' : null}
            inputProps={{ placeholder: 'Search Filters...' }}
            options={savedFilterOptions}
            loading={savedFiltersLoading[filterType]}
            menuPosition="right"
            handleSelect={handleSelectSavedFilter}
            actions={actions}
            selected={selectedSavedFilterId}
            pinnedAccessor="is_default"
            showResultsHeader
            handleClear={handleClearSavedFilter}
          />
        )}
      </div>

      {modal === MANAGE_FILTERS_MODAL && (
        <BasicModal
          data-testid="manage-filters-modal"
          className="saved-filters-modal"
          title={isMyTeamFilter ? 'My Team' : 'My Saved Filters'}
          onClose={handleCloseModal}
          show={!!modal}
          size="small"
          showCloseButton={false}
          footer={
            <Button primary onClick={handleCloseModal}>
              Done
            </Button>
          }
          {...modalProps}
        >
          {showNoFiltersMessage ? (
            <div className="no-filters-wrapper">
              <h1>{noFiltersTitle || 'You have no filters'}</h1>
              <div className="text-center">
                {noFiltersMessage || 'Get started by creating a new filter'}
              </div>
              <Button primary data-testid="create-filter-button" onClick={openCreateFilterModal}>
                {noFiltersButtonLabel || 'Create Filter'}
              </Button>
            </div>
          ) : (
            <>
              {!isMyTeamFilter && !isEmpty(savedFiltersByType) && (
                <div className="saved-filters-summary">
                  <span className="saved-filters-count">
                    {pluralize('saved filter', savedFiltersByType.length, true)}
                  </span>
                  <Button data-testid="new-filter-button" secondary onClick={openCreateFilterModal}>
                    Create Filter
                  </Button>
                </div>
              )}

              <SavedFiltersList
                savedFilters={savedFiltersByType}
                onEditClick={handleEditFilter}
                onDeleteClick={handleDeleteFilter}
              />
            </>
          )}
        </BasicModal>
      )}

      {modal === CREATE_EDIT_FILTER_MODAL && (
        <BasicModal
          data-testid="new-filter-modal"
          title={
            isMyTeamFilter ? (
              'My Team'
            ) : (
              <div className="flex-align-center medium-gap">
                <Button
                  icon
                  secondary
                  type="button"
                  className="svg-button"
                  onClick={handleBackButtonClick}
                >
                  <IconArrowLeft />
                </Button>
                <div>{selectedFilter ? 'Edit' : 'New'} Filter</div>
              </div>
            )
          }
          onClose={handleCloseModal}
          show={!!modal}
          size="small"
          showCloseButton={false}
        >
          <SavedFilterForm
            selectedFilter={selectedFilter}
            filterFormOptions={filterFormOptions}
            filterType={filterType}
          />
        </BasicModal>
      )}
    </>
  )
}
