import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { Button, Loader } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import queryString from 'query-string'
import { fetchPlaylist } from '@/reducers/playlists/playlists.actions'
import { fetchCallExplorer } from '@/reducers/callSearch/callSearch.actions'
import { parseParams } from '@/reducers/callSearch/helpers'
import { VideoPlayerProvider } from '@/components/media/VideoPlayerContext'
import { AudioPlayerProvider } from '@/components/media/AudioPlayerContext'
import { TranscriptProvider } from '@/components/transcript/TranscriptContext'

import { getContextFromEvent } from './components/helpers'
import { CallExplorerTabs } from './CallExplorerTabs'
import { CallExplorerHeader } from './components/CallExplorerHeader'
import { CallExplorerMediaAndTranscript } from './components/CallExplorerMediaAndTranscript'
import { PlaylistDrawer } from '../Playlists/components/PlaylistDrawer'

import './CallExplorerPage.scss'

const CallExplorerPage = () => {
  const [fetched, setFetched] = useState(false)
  const { id: callId } = useParams()
  const dispatch = useDispatch()
  const { search } = useLocation()
  const { callExplorer, callExplorerLoading, error } = useSelector((state) => state.callSearch)
  const { playlist } = useSelector((state) => state.playlists)
  const { playlist_calls: playlistCalls = [] } = playlist || {}
  const { playlistUuid } = queryString.parse(search)

  const {
    transcript,
    postcall_transcript: postcallTranscript,
    metadata = {},
    analysis,
  } = callExplorer
  const { organization_id: organizationId } = metadata

  const getHighlightedEventsFromParams = () => {
    const { deck, checklist, notifications, postcall, keywords } = parseParams(search)
    const highlightedContext = [
      ...deck.selected,
      ...checklist.selected,
      ...notifications.selected,
      ...postcall.selected,
    ].map((context) => {
      const contextArr = context.split(' - ')
      contextArr.shift()

      if (['checklist', 'notifications'].includes(contextArr[0])) {
        return [contextArr[0], contextArr[2]].join(' - ')
      }

      return contextArr.join(' - ')
    })
    if (!isEmpty(keywords)) {
      keywords.map((keyword) => highlightedContext.push(`keywords - ${keyword.value}`))
    }

    const newHighlightedEvents = analysis.filter((eventObj) => {
      const eventContext = getContextFromEvent(eventObj)

      return highlightedContext.some((contextStr) => eventContext === contextStr)
    })
    return newHighlightedEvents.sort((a, b) => a.timestamp - b.timestamp)
  }

  const fetchCall = async () => {
    const { keywords } = parseParams(search)
    await dispatch(fetchCallExplorer({ id: callId, keywords }))
    setFetched(true)
  }

  useEffect(() => {
    fetchCall()
  }, [callId])

  useEffect(() => {
    if (organizationId && playlistUuid) {
      dispatch(fetchPlaylist(playlistUuid, organizationId))
    }
  }, [organizationId, playlistUuid])

  const highlightedEvents = useMemo(getHighlightedEventsFromParams, [analysis])

  if (error.callExplorer) {
    return (
      <div>
        <p>Call in progress, check back later.</p>
        <Button
          type="button"
          primary
          onClick={() => {
            window.location.reload()
          }}
        >
          Reload
        </Button>
      </div>
    )
  }

  return (
    <>
      <CallExplorerHeader playlist={playlist} callId={callId} metadata={metadata} />

      <div className="call-explorer-events-container">
        {callExplorerLoading || !fetched ? (
          <div className="empty loading-container">
            <Loader active data-testid="call-explorer-loading" />
          </div>
        ) : (
          <>
            <AudioPlayerProvider>
              <VideoPlayerProvider>
                <TranscriptProvider transcript={transcript} postcallTranscript={postcallTranscript}>
                  <CallExplorerMediaAndTranscript />
                </TranscriptProvider>
              </VideoPlayerProvider>
            </AudioPlayerProvider>
            <CallExplorerTabs
              highlightedEvents={highlightedEvents}
              callId={callId}
              organizationId={organizationId}
            />
          </>
        )}
        {playlistUuid && playlistCalls.length > 0 && (
          <PlaylistDrawer calls={playlistCalls} currentCall={callId} />
        )}
      </div>
    </>
  )
}

export default CallExplorerPage
