import { fetchingAPI, apiService } from '@/api'
import { toString, defaultsDeep, isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import moment from 'moment'
import queryString from 'query-string'

import { initialPlaybookBody } from '@/views/Playbooks/playbook.helpers'
import { sanitizeBody, convertDurationToSeconds } from './playbook.helpers'

import { setData } from '../scorecards/scorecards.redux'
import * as actions from './playbook.redux'

export const fetchPlaybookVersionHelper = async (dispatch, cid) => {
  dispatch(actions.setLoading({ versions: true }))

  try {
    const versions = await fetchingAPI(
      `${apiService.web}/api/configs/${cid}/versions`,
      'GET',
      dispatch
    )

    dispatch(actions.setPlaybookVersions(versions))
  } catch (err) {
    toast.error('Failed to fetch playbook versions')
  } finally {
    dispatch(actions.setLoading({ versions: false }))
  }
}

export const fetchPlaybookWarningsHelper = async (dispatch, playbook) => {
  dispatch(actions.setLoading({ warnings: true }))

  try {
    if (playbook.current) {
      const warnings = await fetchingAPI(
        `${apiService.playbookWarning}/config_warnings?language=${playbook.language || 'english'}`,
        'POST',
        dispatch,
        JSON.stringify(playbook.body)
      )

      dispatch(actions.setPlaybookWarnings(warnings))
    }
  } catch (err) {
    toast.error('Failed to fetch playbook warnings')
  } finally {
    dispatch(actions.setLoading({ warnings: false }))
  }
}

export const fetchPlaybookWarnings = () => {
  return async (dispatch, getState) => {
    dispatch(actions.setLoading({ warnings: true }))

    try {
      const playbook = getState().playbook?.playbook

      if (playbook.current) {
        const warnings = await fetchingAPI(
          `${apiService.playbookWarning}/config_warnings?language=${
            playbook.language || 'english'
          }`,
          'POST',
          dispatch,
          JSON.stringify(playbook.body)
        )

        dispatch(actions.setPlaybookWarnings(warnings))
      }
    } catch (err) {
      toast.error('Failed to fetch playbook warnings')
    } finally {
      dispatch(actions.setLoading({ warnings: false }))
    }
  }
}

export const fetchNovelChecklistItems = (cid, id) => {
  return async (dispatch) => {
    try {
      const variationContent = await fetchingAPI(
        `${apiService.sayableContent}/agent-checklist?playbook_uuid=${cid}&config_id=${id}`,
        'GET',
        dispatch
      )
      const novelContent = []
      variationContent.forEach((variations) => {
        const { items, user } = variations
        const novelItems = items.filter(
          ({ item_type, created_by }) => item_type === 'novel' && created_by === user.user_id
        )

        if (!novelItems.length) {
          return
        }

        const userName = `${user.first_name} ${user.last_name}`
        novelItems.forEach((item) => {
          const totalMs = new Date(`${item.created_at}Z`).getTime()
          const novelItem = {
            ...item,
            user: userName,
            created_at: totalMs,
            name: item.text,
          }
          novelContent.push(novelItem)
        })
      })
      novelContent.sort((a, b) => a.created_at > b.created_at)
      dispatch(actions.setNovelChecklistItems(novelContent))
    } catch (err) {
      toast.error('Failed to fetch novel checklist items')
    }
  }
}

export const fetchPlaybookSectionWarnings = (sectionName) => {
  return async (dispatch, getState) => {
    try {
      const playbook = getState().playbook?.playbook

      if (playbook.current && sectionName === 'deck') {
        const entries = JSON.parse(JSON.stringify(playbook.body[sectionName]?.entries))
        const convertedEntries = convertDurationToSeconds(entries)

        const sectionWarnings = await fetchingAPI(
          `${apiService.playbookWarning}/section_warnings/${sectionName}?language=${
            playbook.language || 'english'
          }`,
          'POST',
          dispatch,
          JSON.stringify({ entries: convertedEntries })
        )

        dispatch(actions.setPlaybookSectionWarnings(sectionName)(sectionWarnings))
      }
    } catch (err) {
      toast.error('Failed to fetch playbook section warnings')
    }
  }
}

export const fetchPlaybookEntryWarnings = (sectionName, entryValues, entryId) => {
  return async (dispatch, getState) => {
    try {
      const playbook = getState().playbook?.playbook

      if (playbook.current) {
        const cleanedEntryValues = { ...entryValues }
        if (sectionName === 'deck') {
          delete cleanedEntryValues.display_duration_unit
        }

        const entryWarnings = await fetchingAPI(
          `${apiService.playbookWarning}/entry_warnings/${sectionName}?language=${
            playbook.language || 'english'
          }`,
          'POST',
          dispatch,
          JSON.stringify({
            entry_data: cleanedEntryValues,
            entry_id: entryId,
            section_entries: playbook.body[sectionName]?.entries,
          })
        )

        const playbookWarnings = getState().playbook?.warnings

        if (!isEmpty(entryWarnings) || playbookWarnings[sectionName][entryId]) {
          const payload = { entryId, entryWarnings }

          dispatch(actions.setPlaybookEntryWarnings(sectionName)(payload))
        }
      }
    } catch (err) {
      toast.error('Failed to fetch playbook entry warnings')
    }
  }
}

export const fetchPlaybook = (playbookId) => {
  return async (dispatch) => {
    dispatch(actions.clearValidationError())
    dispatch(actions.setLoading({ playbook: true }))

    try {
      const { config, mostRecentId } = await fetchingAPI(
        `${apiService.web}/api/configs/${playbookId}`,
        'GET',
        dispatch
      )
      // Ensure all the default fields are present, because the API does not return with the
      // values if nothing exists
      dispatch(
        actions.setPlaybook({
          ...config,
          mostRecentId,
          body: defaultsDeep(config.body, initialPlaybookBody),
        })
      )

      await fetchPlaybookVersionHelper(dispatch, config.cid)

      try {
        await fetchPlaybookWarningsHelper(dispatch, config)
      } catch (err) {
        toast.error('Failed to fetch playbook warnings')
      }
      try {
        await dispatch(fetchNovelChecklistItems(config.cid, config.id))
      } catch {
        // do nothing, but don't fail
      }
    } catch (err) {
      toast.error('Failed to fetch playbook')
    } finally {
      dispatch(actions.setLoading({ playbook: false }))
    }
  }
}

// Make a new playbook version from an existing playbook
export const createPlaybookVersion = (playbook, history, section, readyToRestore) => {
  return async (dispatch) => {
    dispatch(actions.setLoading({ savePlaybook: true }))

    const updatedPlaybook = {
      name: playbook.name,
      cid: playbook.cid,
      began_editing: playbook.began_editing,
      organization_id: playbook.organization_id,
    }

    if (playbook.body) {
      const sanitizedBody = JSON.parse(JSON.stringify(playbook.body))

      // Convert duration to seconds and remove unit
      if (sanitizedBody.deck?.entries) {
        sanitizedBody.deck.entries = convertDurationToSeconds(sanitizedBody.deck.entries)
        updatedPlaybook.body = sanitizeBody(sanitizedBody)
      }
    }

    try {
      const newPlaybookVersion = await fetchingAPI(
        `${apiService.web}/api/configs`,
        'POST',
        dispatch,
        JSON.stringify(updatedPlaybook)
      )
      if (history) {
        history.push(`/playbooks/${newPlaybookVersion.id}/${section}`)
        toast.success(readyToRestore ? 'Playbook restored' : 'Playbook saved!')
      }
      return newPlaybookVersion.id
    } catch (err) {
      const { section, entry, readableError } = err

      dispatch(
        actions.setValidationError({ accessor: section, entry, errorMessage: readableError })
      )
      toast.error(
        `Failed to ${readyToRestore ? 'restore' : 'update'} playbook. Error: ${toString(
          readableError
        )}`
      )
      return undefined
    } finally {
      // Hack to ensure playbook is not published immediately after saving to give the database time to update
      setTimeout(() => {
        dispatch(actions.setLoading({ savePlaybook: false }))
      }, 3000)
    }
  }
}

export const publishPlaybookVersion = (playbookId, showToast = true) => {
  return async (dispatch) => {
    dispatch(actions.setLoading({ savePlaybook: true }))
    let success = false

    try {
      await fetchingAPI(`${apiService.web}/api/configs/${playbookId}`, 'PATCH', dispatch)
      dispatch(actions.activatePlaybookVersion())
      if (showToast) {
        toast.success('Playbook published!')
      }
      success = true
    } catch (err) {
      toast.error('Failed to publish playbook')
    } finally {
      dispatch(actions.setLoading({ savePlaybook: false }))
    }

    return success
  }
}

export const getScorecardsAssociatedToPlaybook = (playbookId) => {
  return async (dispatch) => {
    try {
      const scorecardAssociations = await fetchingAPI(
        `${apiService.scorecard}/scoring/scorecards/validate_playbook/${playbookId}`,
        'GET',
        dispatch
      )

      dispatch(setData('scorecardAssociations', scorecardAssociations || []))
    } catch (err) {
      console.error(err)
    }
  }
}

export const updateScorecardPlaybookVersion = (id, organizationId) => {
  return async (dispatch) => {
    try {
      await fetchingAPI(
        `${apiService.scorecard}/scoring/scorecards/update_playbook_version/${id}?requested_organization_id=${organizationId}`,
        'GET',
        dispatch
      )
    } catch (err) {
      console.error(err)
    }
  }
}

export const fetchChecklistVariations = (playbookId, id) => {
  return async (dispatch) => {
    try {
      const checklistVariations = await fetchingAPI(
        `${apiService.sayableContent}/agent-checklist?playbook_uuid=${playbookId}&config_id=${id}`,
        'GET',
        dispatch
      )

      dispatch(actions.setChecklistVariations(checklistVariations))
    } catch (err) {
      toast.error('Failed to fetch checklist variations')
    }
  }
}

export const fetchChecklistItemVariations = (entryId, playbookId) => {
  return async (dispatch) => {
    try {
      const checklistItemVariations = await fetchingAPI(
        `${apiService.sayableContent}/agent-checklist?playbook_uuid=${playbookId}&checklist_properties_uuid=${entryId}`,
        'GET',
        dispatch
      )

      dispatch(actions.setChecklistItemVariations(checklistItemVariations, entryId))
    } catch (err) {
      toast.error('Failed to fetch checklist item variations')
    }
  }
}

export const deleteChecklistItemVariation = (userId, entryId, playbookUuid) => {
  return async (dispatch) => {
    try {
      await fetchingAPI(
        `${apiService.sayableContent}/agent-checklist`,
        'DELETE',
        dispatch,
        JSON.stringify({
          user_id: userId,
          checklist_props_uuid: entryId,
          playbook_uuid: playbookUuid,
        })
      )

      const checklistItemVariations = await fetchingAPI(
        `${apiService.sayableContent}/agent-checklist?playbook_uuid=${playbookUuid}&checklist_properties_uuid=${entryId}`,
        'GET',
        dispatch
      )

      dispatch(actions.setChecklistItemVariations(checklistItemVariations, entryId))

      toast.success('Restored checklist item to default')
    } catch (err) {
      toast.error('Failed to restore checklist item variation')
    }
  }
}

export const fetchAdditionalPhrases = (phrase, index, formikProps) => async (dispatch) => {
  dispatch(actions.setLoading({ smartPhraseSuggestions: index }))

  try {
    const response = await fetchingAPI(
      `${apiService.web}/api/slp/phrase_suggestion`,
      'POST',
      dispatch,
      JSON.stringify({ ...phrase })
    )

    formikProps.setFieldValue(
      `trigger.smart_phrases[${index}].suggestions`,
      response?.suggestions || []
    )
  } catch {
  } finally {
    dispatch(actions.setLoading({ smartPhraseSuggestions: null }))
  }
}

export const getSummaryConfigForPlaybook = (id) => {
  return async (dispatch, getState) => {
    const { organizationid } = getState().currentUser

    dispatch(actions.setLoading({ prompt: true }))

    try {
      const { prompt_id } = await fetchingAPI(
        `${apiService.summary}/summary-configs?playbook_cid=${id}`,
        'GET',
        dispatch
      )
      const prompt = await fetchingAPI(
        `${apiService.summary}/prompts/${prompt_id}`,
        'GET',
        dispatch
      )
      dispatch(actions.setRTNPrompt(prompt))

      if (organizationid === 1) {
        const modelOptions = await fetchingAPI(
          `${apiService.summary}/models?feature=rtn`,
          'GET',
          dispatch
        )
        dispatch(actions.setRTNModelOptions(modelOptions.llm_model_options))
      }
    } catch (err) {
      console.error(err)
      toast.error('Failed to load prompt for playbook')
    } finally {
      dispatch(actions.setLoading({ prompt: false }))
    }
  }
}

export const updateSummaryConfigForPlaybook = (cid, prompt) => async (dispatch) => {
  try {
    const { id: prompt_id } = await fetchingAPI(
      `${apiService.summary}/prompts`,
      'POST',
      dispatch,
      JSON.stringify(prompt)
    )

    await fetchingAPI(
      `${apiService.summary}/summary-configs`,
      'POST',
      dispatch,
      JSON.stringify({ playbook_cid: cid, prompt: prompt.user_prompt, prompt_id })
    )
    toast.success('Prompt updated successfully')
  } catch (err) {
    console.error(err)
    toast.error('Failed to update prompt for playbook')
  }
}

export const createRTNTest = (prompt, promptTest) => async (dispatch) => {
  try {
    const body = JSON.stringify({ ...promptTest, action: 'create' })
    const promptTestResponse = await fetchingAPI(
      `${apiService.summary}/prompts/${prompt.id}/tests`,
      'POST',
      dispatch,
      body
    )
    const promptWithNewTest = {
      ...prompt,
      prompt_tests: [...prompt.prompt_tests, promptTestResponse],
    }
    dispatch(actions.setRTNPrompt(promptWithNewTest))
  } catch (err) {
    toast.error('Failed to create test')
  }
}

export const deleteRTNTest = (prompt, promptTestId) => async (dispatch) => {
  try {
    const promptTestResponse = await fetchingAPI(
      `${apiService.summary}/prompts/${prompt.id}/tests/${promptTestId}`,
      'DELETE',
      dispatch
    )
    const promptWithoutTest = {
      ...prompt,
      prompt_tests: prompt.prompt_tests.filter(
        (promptTest) => promptTest.id !== promptTestResponse.id
      ),
    }
    dispatch(actions.setRTNPrompt(promptWithoutTest))
  } catch (err) {
    toast.error('Failed to delete test')
  }
}

export const getRTNTestResults = (promptId, tempPrompt) => async (dispatch) => {
  dispatch(actions.setLoading({ promptTests: true }))
  try {
    const body = JSON.stringify({ ...tempPrompt, action: 'execute' })
    return await fetchingAPI(
      `${apiService.summary}/prompts/${promptId}/tests`,
      'POST',
      dispatch,
      body
    )
  } catch (err) {
    toast.error('Failed to run tests')
    return []
  } finally {
    dispatch(actions.setLoading({ promptTests: false }))
  }
}

export const fetchRTNMetrics = (organizationId, playbookCid) => async (dispatch, getState) => {
  const { organizationid } = getState().currentUser
  dispatch(actions.setLoading({ rtnMetrics: true }))
  const startDate = moment().subtract(30, 'days').startOf('day').toISOString()
  const endDate = moment().endOf('day').toISOString()

  const urlParams = {
    requested_organization_id: organizationId || organizationid,
    start_date: startDate,
    end_date: endDate,
  }

  if (playbookCid) {
    urlParams.playbook_cid = playbookCid
  }

  const url = `${apiService.summary}/summaries/metrics?${queryString.stringify(urlParams)}`

  try {
    const metrics = await fetchingAPI(url, 'GET', dispatch)
    dispatch(actions.setRTNMetrics(metrics))
  } catch (err) {
    toast.error('Failed to fetch metrics')
  } finally {
    dispatch(actions.setLoading({ rtnMetrics: false }))
  }
}
