import React from 'react'
import { IconPencil, IconTrash } from '@tabler/icons-react'

import { formatTime } from '@/utils/helpers'
import { Pill } from '@/components/pills/Pill'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'

export const SavedFiltersList = ({ savedFilters = [], onEditClick, onDeleteClick }) => {
  const rows = savedFilters.map((filter) => ({
    id: filter.uuid,
    uuid: filter.uuid,
    created_on: filter.created_on,
    is_default: filter.is_default,
    name: {
      cellProps: {
        className: 'collapsing',
      },
      value: filter.name,
      as: (
        <div className="flex-align-center small-gap">
          <span>{filter.name}</span>
          {filter.is_default && (
            <Pill brand small emphasized>
              Default
            </Pill>
          )}
        </div>
      ),
    },
  }))

  const columns = [
    { accessor: 'name', label: 'Name' },
    {
      accessor: 'created_on',
      label: 'Date Created',
      format: (value) => formatTime(value, 'MMMM D, YYYY'),
    },
  ]

  const actions = [
    {
      label: 'Edit',
      icon: <IconPencil />,
      fn: (event, row) => {
        onEditClick(row.uuid)
      },
    },
    {
      label: 'Delete',
      icon: <IconTrash />,
      fn: (event, row) => {
        onDeleteClick(row.uuid)
      },
    },
  ]

  return (
    <div className="saved-filters-list-wrapper">
      <AdvancedTable
        striped={false}
        pinned="is_default"
        rows={rows}
        columns={columns}
        actions={actions}
        pagination
        rowsPerPage={15}
      />
    </div>
  )
}
