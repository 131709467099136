import queryString from 'query-string'
import { isEmpty, isNil, toNumber } from 'lodash'
import { toast } from 'react-toastify'
import moment from 'moment'

import {
  getFilterQueryString,
  getTagsWithCategories,
  explodeItemsByUser,
  getPercentNumerical,
  findPreviousDateRangeFromCurrentFilters,
  getFilterBodyString,
} from '@/utils/helpers'
import { fetchingAPI, apiService } from '@/api'
import { customStaticRanges } from '@/components/helpers'
import { ANALYTICS_FILTER_TYPE } from '@/reducers/savedFilters/savedFilters.constants'
import { fetchSavedFilters } from '@/reducers/savedFilters/savedFilters.actions'
import {
  setInitialLoadComplete,
  setLoading,
  setFilter,
  setCallsViewFilters,
  setDateRangeFilter,
  setData,
  clearReportData,
  clearFilters,
  clearCallsViewFilters,
  clearFiltersMinusOrg,
} from './analytics.redux'

async function applyOverviewPageFilters(dispatch, filterString, previousFilterString) {
  dispatch(clearReportData())
  dispatch(setLoading({ overviewPage: true }))

  try {
    const [
      callStatsResponse,
      totalUserCountResponse,
      checklistUsageResponse,
      topNotificationsAndDecksResponse,
    ] = await Promise.all([
      fetchingAPI(`${apiService.reporting}/api/calls/stats?${filterString}`, 'GET', dispatch),
      fetchingAPI(`${apiService.reporting}/api/users/total_count?${filterString}`, 'GET', dispatch),
      fetchingAPI(`${apiService.reporting}/api/checklist/usage?${filterString}`, 'GET', dispatch),
      fetchingAPI(
        `${apiService.reporting}/api/playbooks/top_notifications_and_decks?${filterString}`,
        'GET',
        dispatch
      ),
    ])

    // Win Percent
    const winPercent = getPercentNumerical(
      callStatsResponse.win_count,
      callStatsResponse.calls_count
    )

    dispatch(
      setData({
        callsCount: callStatsResponse.calls_count,
        winCount: callStatsResponse.win_count,
        averageHandleTime: callStatsResponse.average_handle_time,
        activeUserCount: callStatsResponse.active_user_count,
        totalUserCount: totalUserCountResponse,
        checklistUsage: checklistUsageResponse,
        topNotifications: topNotificationsAndDecksResponse.top_notifications,
        topDeckItems: topNotificationsAndDecksResponse.top_deck_items,
        winPercent,
      })
    )
  } catch (err) {
    toast.error('Failed to apply filters')
  } finally {
    dispatch(setLoading({ overviewPage: false }))
  }

  dispatch(setLoading({ overviewPageComparisons: true }))

  try {
    const [
      previousCallStatsResponse,
      previousTotalUserCountResponse,
      previousChecklistUsageResponse,
    ] = await Promise.all([
      fetchingAPI(
        `${apiService.reporting}/api/calls/stats?${previousFilterString}`,
        'GET',
        dispatch
      ),
      fetchingAPI(
        `${apiService.reporting}/api/users/total_count?${previousFilterString}`,
        'GET',
        dispatch
      ),
      fetchingAPI(
        `${apiService.reporting}/api/checklist/usage?${previousFilterString}`,
        'GET',
        dispatch
      ),
    ])

    // Win Percent
    const previousWinPercent = getPercentNumerical(
      previousCallStatsResponse.win_count,
      previousCallStatsResponse.calls_count
    )

    dispatch(
      setData({
        previousCallsCount: previousCallStatsResponse.calls_count,
        previousWinCount: previousCallStatsResponse.win_count,
        previousAverageHandleTime: previousCallStatsResponse.average_handle_time,
        previousActiveUserCount: previousCallStatsResponse.active_user_count,
        previousTotalUserCount: previousTotalUserCountResponse,
        previousChecklistUsage: previousChecklistUsageResponse,
        previousWinPercent,
      })
    )
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setLoading({ overviewPageComparisons: false }))
  }
}

async function applyChecklistPageFilters(dispatch, filterString, own_organization_id) {
  dispatch(setLoading({ checklistPage: true }))

  try {
    const [
      checklistItemsResponse,
      checklistItemsByDateResponse,
      checklistItemsByUserResponse,
      checklistItemsByCallResponse,
    ] = await Promise.all([
      fetchingAPI(`${apiService.reporting}/api/checklist/items?${filterString}`, 'GET', dispatch),
      fetchingAPI(
        `${apiService.reporting}/api/checklist/items_by_date?${filterString}`,
        'GET',
        dispatch
      ),
      fetchingAPI(
        `${apiService.reporting}/api/checklist/items_by_user?${filterString}`,
        'GET',
        dispatch
      ),
      fetchingAPI(
        `${apiService.reporting}/api/checklist/items_by_call?${filterString}`,
        'GET',
        dispatch
      ).catch((err) => {
        if (own_organization_id === 1) {
          // only catch error for org 1
          // items_by_call api can return a transcript and fails if org 1 queries this page and the org being queried is unredacted
          return { calls_data: [], older_cursor: null, newer_cursor: null }
        }
        throw err
      }),
    ])

    const explodedData = explodeItemsByUser(
      checklistItemsByUserResponse.items_by_user,
      checklistItemsByUserResponse.users,
      'checklist'
    )

    dispatch(
      setData({
        checklistItems: checklistItemsResponse,
        checklistUsage: checklistItemsByDateResponse.usage_by_date,
        checklistUsageByItem: checklistItemsByDateResponse.usage_by_item,
        checklistItemsByUser: explodedData,
        checklistItemsByDate: checklistItemsByDateResponse.items_by_date,
        checklistItemsByCall: checklistItemsByCallResponse.calls_data,
        checklistItemsByCallCursors: {
          olderCursor: checklistItemsByCallResponse.older_cursor,
          newerCursor: checklistItemsByCallResponse.newer_cursor,
        },
      })
    )
  } catch (err) {
    toast.error('Failed to apply filters')
  } finally {
    dispatch(setLoading({ checklistPage: false }))
  }
}

async function applyDeckPageFilters(
  dispatch,
  filterQueryString,
  filterBodyString,
  own_organization_id
) {
  dispatch(
    setLoading({
      deckPage: true,
      deckPageWins: true,
    })
  )

  try {
    // .then() is the only way to handle indiviual parallelization
    Promise.all([
      fetchingAPI(
        `${apiService.reporting}/api/dynamic_prompt/items`,
        'POST',
        dispatch,
        filterBodyString
      ),
      fetchingAPI(
        `${apiService.reporting}/api/dynamic_prompt/items_by_date`,
        'POST',
        dispatch,
        filterBodyString
      ),
      fetchingAPI(
        `${apiService.reporting}/api/dynamic_prompt/items_by_user`,
        'POST',
        dispatch,
        filterBodyString
      ),
      fetchingAPI(
        `${apiService.reporting}/api/dynamic_prompt/items_by_call`,
        'POST',
        dispatch,
        filterBodyString
      ).catch((err) => {
        if (own_organization_id === 1) {
          // only catch error for org 1
          // items_by_call api can return a transcript and fails if org 1 queries this page and the org being queried is unredacted
          return { calls_data: [], older_cursor: null, newer_cursor: null }
        }
        throw err
      }),
      fetchingAPI(
        `${apiService.reporting}/api/usage/decklist_response_count_by_date?${filterQueryString}`,
        'GET',
        dispatch
      ),
      fetchingAPI(
        `${apiService.reporting}/api/usage/decklist_response_count_by_date_and_user?${filterQueryString}`,
        'GET',
        dispatch
      ),
    ]).then(
      ([
        deckItemsResponse,
        deckItemsByDateResponse,
        deckItemsByUserResponse,
        deckItemsByCallResponse,
        decklistResponseCountByDateResponse,
        decklistResponseCountByDateAndUserResponse,
      ]) => {
        const explodedData = explodeItemsByUser(
          deckItemsByUserResponse.items_by_user,
          deckItemsByUserResponse.users,
          'deck'
        )

        dispatch(
          setData({
            deckItems: deckItemsResponse,
            deckUsage: deckItemsByDateResponse.usage_by_date,
            deckUsageByItem: deckItemsByDateResponse.usage_by_item,
            deckItemsByDate: deckItemsByDateResponse.items_by_date,
            deckItemsByUser: explodedData,
            deckItemsByCall: deckItemsByCallResponse.calls_data,
            responseCountsByDate: decklistResponseCountByDateResponse,
            responseCountsByDateAndUser: decklistResponseCountByDateAndUserResponse,
            deckItemsByCallCursors: {
              olderCursor: deckItemsByCallResponse.older_cursor,
              newerCursor: deckItemsByCallResponse.newer_cursor,
            },
          })
        )
        dispatch(setLoading({ deckPage: false }))
      }
    )

    const deckWinRateResponse = await fetchingAPI(
      `${apiService.reporting}/api/dynamic_prompt/win_rate`,
      'POST',
      dispatch,
      filterBodyString
    )

    dispatch(setData({ deckWinRate: deckWinRateResponse }))
    dispatch(setLoading({ deckPageWins: false }))
  } catch (err) {
    toast.error('Failed to apply filters')
    dispatch(
      setLoading({
        deckPage: false,
        deckPageWins: false,
      })
    )
  }
}

async function applyNotificationsPageFilters(dispatch, filterString, own_organization_id) {
  dispatch(setLoading({ notificationsPage: true }))

  try {
    const [
      notificationsResponse,
      notificationsByDateResponse,
      notificationsByUserResponse,
      notificationsItemsByCallResponse,
    ] = await Promise.all([
      fetchingAPI(
        `${apiService.reporting}/api/notifications/items?${filterString}`,
        'GET',
        dispatch
      ),
      fetchingAPI(
        `${apiService.reporting}/api/notifications/items_by_date?${filterString}`,
        'GET',
        dispatch
      ),
      fetchingAPI(
        `${apiService.reporting}/api/notifications/items_by_user?${filterString}`,
        'GET',
        dispatch
      ),
      fetchingAPI(
        `${apiService.reporting}/api/notifications/items_by_call?${filterString}`,
        'GET',
        dispatch
      ).catch((err) => {
        if (own_organization_id === 1) {
          // only catch error for org 1
          // items_by_call api can return a transcript and fails if org 1 queries this page and the org being queried is unredacted
          return { calls_data: [], older_cursor: null, newer_cursor: null }
        }
        throw err
      }),
    ])
    const explodedData = explodeItemsByUser(
      notificationsByUserResponse.items_by_user,
      notificationsByUserResponse.users,
      'notifications'
    )
    dispatch(
      // easier to just keep "Items" even if it doesn't make sense here
      setData({
        notificationsItems: notificationsResponse,
        notificationsUsage: notificationsByDateResponse.usage_by_date,
        notificationsUsageByItem: notificationsByDateResponse.usage_by_item,
        notificationsItemsByDate: notificationsByDateResponse.items_by_date,
        notificationsItemsByUser: explodedData,
        notificationsItemsByCall: notificationsItemsByCallResponse.calls_data,
        notificationsItemsByCallCursors: {
          olderCursor: notificationsItemsByCallResponse.older_cursor,
          newerCursor: notificationsItemsByCallResponse.newer_cursor,
        },
      })
    )
  } catch (err) {
    toast.error('Failed to apply filters')
  } finally {
    dispatch(setLoading({ notificationsPage: false }))
  }
}

async function applyPostCallPageFilters(dispatch, filterString, own_organization_id) {
  dispatch(setLoading({ postcallPage: true }))

  try {
    const [
      postcallResponse,
      postcallByDateResponse,
      postcallItemUsageByUser,
      postcallItemsByCallResponse,
    ] = await Promise.all([
      fetchingAPI(`${apiService.reporting}/api/postcall/items?${filterString}`, 'GET', dispatch),
      fetchingAPI(
        `${apiService.reporting}/api/postcall/items_by_date?${filterString}`,
        'GET',
        dispatch
      ),
      fetchingAPI(
        `${apiService.reporting}/api/postcall/items_by_user?${filterString}`,
        'GET',
        dispatch
      ),
      fetchingAPI(
        `${apiService.reporting}/api/postcall/items_by_call?${filterString}`,
        'GET',
        dispatch
      ).catch((err) => {
        if (own_organization_id === 1) {
          // only catch error for org 1
          // items_by_call api can return a transcript and fails if org 1 queries this page and the org being queried is unredacted
          return { calls_data: [], older_cursor: null, newer_cursor: null }
        }
        throw err
      }),
    ])
    const explodedData = explodeItemsByUser(
      postcallItemUsageByUser.items_by_user,
      postcallItemUsageByUser.users,
      'postcall'
    )

    dispatch(
      setData({
        postcallItems: postcallResponse,
        postcallUsage: postcallByDateResponse.usage_by_date,
        postcallUsageByItem: postcallByDateResponse.usage_by_item,
        postcallItemsByDate: postcallByDateResponse.items_by_date,
        postcallItemsByUser: explodedData,
        postcallItemsByCall: postcallItemsByCallResponse.calls_data,
        postcallItemsByCallCursors: {
          olderCursor: postcallItemsByCallResponse.older_cursor,
          newerCursor: postcallItemsByCallResponse.newer_cursor,
        },
      })
    )
  } catch (err) {
    toast.error('Failed to apply filters')
  } finally {
    dispatch(setLoading({ postcallPage: false }))
  }
}

async function applyUsagePageFilters(dispatch, filterString) {
  dispatch(setLoading({ usagePage: true }))

  try {
    const [
      userCountsByDateResponse,
      callCountsByDateResponse,
      callCountsByUserResponse,
      usersWithoutCallsResponse,
    ] = await Promise.all([
      fetchingAPI(
        `${apiService.reporting}/api/usage/user_counts_by_date?${filterString}`,
        'GET',
        dispatch
      ),
      fetchingAPI(
        `${apiService.reporting}/api/usage/call_counts_by_date?${filterString}`,
        'GET',
        dispatch
      ),
      fetchingAPI(
        `${apiService.reporting}/api/usage/call_counts_by_user?${filterString}`,
        'GET',
        dispatch
      ),
      fetchingAPI(
        `${apiService.reporting}/api/usage/users_without_calls?${filterString}`,
        'GET',
        dispatch
      ),
    ])

    dispatch(
      setData({
        userCountsByDate: userCountsByDateResponse,
        callCountsByDate: callCountsByDateResponse,
        callCountsByUser: callCountsByUserResponse,
        usersWithoutCalls: usersWithoutCallsResponse,
      })
    )
  } catch (err) {
    toast.error('Failed to apply filters')
  } finally {
    dispatch(setLoading({ usagePage: false }))
  }
}

export const fetchTagsByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading({ tags: true }))

  try {
    const [tags, tagCategories] = await Promise.all([
      fetchingAPI(`${apiService.web}/api/organizations/${organizationId}/tags`, 'GET', dispatch),
      fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/tags/categories`,
        'GET',
        dispatch
      ),
    ])

    const tagsOptionsWithCategories = getTagsWithCategories(tags, tagCategories)

    dispatch(setData({ tags: tagsOptionsWithCategories }))
  } catch (err) {
    toast.error('Failed to fetch tags')
  } finally {
    dispatch(setLoading({ tags: false }))
  }
}

export const fetchAgentsByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading({ agents: true }))

  try {
    const { users } = await fetchingAPI(
      `${apiService.web}/api/organizations/${organizationId}/users`,
      'GET',
      dispatch
    )

    users.sort((a, b) => a.last_name.localeCompare(b.last_name))
    const agentOptions = users.map((agent) => ({
      value: agent.id,
      label: `${agent.first_name} ${agent.last_name}`,
    }))

    dispatch(setData({ agents: agentOptions }))
  } catch (err) {
    toast.error('Failed to fetch agents')
  } finally {
    dispatch(setLoading({ agents: false }))
  }
}

export const fetchPlaybooksByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading({ playbooks: true }))

  try {
    const configProperties = queryString.stringify({ requested_properties: 'id,name,cid' })
    const playbooks = await fetchingAPI(
      `${apiService.web}/api/${organizationId}/configs?${configProperties}`,
      'GET',
      dispatch
    )
    const playbookOptions = playbooks
      .map((playbook) => ({
        value: playbook.cid,
        label: playbook.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

    dispatch(setData({ playbooks: playbookOptions }))
  } catch (err) {
    toast.error('Failed to fetch playbooks')
  } finally {
    dispatch(setLoading({ playbooks: false }))
  }
}

export const fetchCategoriesByOrgSectionAndPlaybooks =
  (organizationId, section, playbooks = []) =>
  async (dispatch) => {
    dispatch(setLoading({ [`${section}Categories`]: true }))

    try {
      const filters = queryString.stringify({
        organization_id: organizationId,
        playbooks: playbooks.map((x) => x.value),
      })

      const categories = await fetchingAPI(
        `${apiService.web}/api/configs/${section}_categories?${filters}`,
        'GET',
        dispatch
      )
      const categoryOptions = categories[`${section}_categories`]
        .sort((a, b) => a.localeCompare(b))
        .map((cat) => ({ value: cat, label: cat }))

      dispatch(setData({ [`${section}Categories`]: categoryOptions }))
    } catch (err) {
      toast.error('Failed to fetch categories')
    } finally {
      dispatch(setLoading({ [`${section}Categories`]: false }))
    }
  }

export const fetchPlaybookData = (filters) => async (dispatch) => {
  dispatch(setLoading({ playbookData: true }))
  const { organization: organization_id, startDate: start_date, endDate: end_date } = filters
  try {
    const playbookRes = await Promise.all(
      filters.playbooks.map(async (playbook) => {
        try {
          const body = { start_date, end_date, organization_id, config_cid: playbook.value }
          return await fetchingAPI(
            `${apiService.reporting}/api/playbook_event_filters?config_cid=${playbook.value}`,
            'POST',
            dispatch,
            JSON.stringify(body)
          )
        } catch {
          return { config_cid: playbook.value, error: true }
        }
      })
    )
    const playbookResUpdated = playbookRes.filter((playbookRes) => {
      return isNil(playbookRes.error)
    })
    const updatedPlaybookData = []

    playbookResUpdated.forEach((playbook) => {
      playbook.deck = playbook?.deck?.map((category) => {
        const updatedSubcategories = category.items.map((item) => {
          if (!item.has_sub_items) {
            return item
          }
          const updatedItems = [...item.items, 'No Response Used']

          return { ...item, items: updatedItems }
        })

        return {
          ...category,
          items: updatedSubcategories,
          playbookName: playbook.playbook_name,
          cid: playbook.cid,
        }
      })
      updatedPlaybookData.push(playbook)
    })
    dispatch(
      setData({
        playbookData: updatedPlaybookData,
      })
    )
  } catch {
    // Do nothing!
  } finally {
    dispatch(setLoading({ playbookData: false }))
  }
}

export const fetchDispositionsByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading({ dispositions: true }))

  try {
    const dispositions = await fetchingAPI(
      `${apiService.reporting}/api/dispositions?organization_id=${organizationId}`,
      'GET',
      dispatch
    )
    const dispoOptions = dispositions
      .map(({ name }) => ({ value: name, label: name }))
      .sort((a, b) => a.label.localeCompare(b.label))
    dispatch(setData({ dispositions: dispoOptions }))
  } catch (err) {
    toast.error('Failed to fetch dispositions')
  } finally {
    dispatch(setLoading({ dispositions: false }))
  }
}

export const fetchAllData = (organizationId) => async (dispatch, getState) => {
  const {
    currentUser,
    analytics: { data },
  } = getState()

  // Load default data for Balto Admin
  if (currentUser.organizationid === 1 && organizationId === 1) {
    dispatch(setFilter({ organization: organizationId }))
  }

  if (isEmpty(data.tags)) {
    dispatch(fetchTagsByOrg(organizationId))
  }

  if (isEmpty(data.playbooks)) {
    dispatch(fetchPlaybooksByOrg(organizationId))
  }

  if (isEmpty(data.agents)) {
    dispatch(fetchAgentsByOrg(organizationId))
  }

  if (isEmpty(data.deckCategories)) {
    dispatch(fetchCategoriesByOrgSectionAndPlaybooks(organizationId, 'deck'))
  }

  if (isEmpty(data.postcallCategories)) {
    dispatch(fetchCategoriesByOrgSectionAndPlaybooks(organizationId, 'postcall'))
  }

  if (isEmpty(data.dispositions)) {
    dispatch(fetchDispositionsByOrg(organizationId))
  }
}

/**
 * Pagination of calls
 *
 * In lieu of using the traditional page/total/offset system of pagination, this expensive operation
 * is using a more performant database cursor, which allows us to pass a pointer to an ordered set
 * and get the next set of results, including whether or not we can continue forward or backward.
 *
 * Passing no cursor = newest results
 * Passing the newer_cursor = newer set of results
 * Passing the older_cursor = older set of results
 * Passing no cursor and oldest=1 = oldest results
 *
 * @param {string} cursor Unique string pointing to current position in the set of results
 * @param {number} oldest whether or not to show the least recent results
 * @param {string} section string indicating which section of analytics data to apply changes to
 */
export const paginateItemsByCall =
  (cursor = '', oldest = '', section) =>
  async (dispatch, getState) => {
    const { analytics } = getState()
    const filterString = getFilterQueryString(analytics.filters, section)
    let callsViewFilterString = ''

    dispatch(setLoading({ [`${section}ItemsByCall`]: true }))

    if (section === 'deck') {
      callsViewFilterString = `&${queryString.stringify(analytics.callsViewFilters)}`
    }

    try {
      const url_section = section === 'deck' ? 'dynamic_prompt' : section
      const response = await fetchingAPI(
        `${apiService.reporting}/api/${url_section}/items_by_call?${filterString}${callsViewFilterString}&pagination_cursor=${cursor}&pagination_oldest=${oldest}`,
        'GET',
        dispatch
      )

      dispatch(
        setData({
          [`${section}ItemsByCall`]: response.calls_data,
          [`${section}ItemsByCallCursors`]: {
            olderCursor: response.older_cursor,
            newerCursor: response.newer_cursor,
          },
        })
      )
    } catch (err) {
      toast.error('Failed to fetch call data')
    } finally {
      dispatch(setLoading({ [`${section}ItemsByCall`]: false }))
    }
  }

export const applyFilters = (section) => async (dispatch, getState) => {
  // clear any sub-filters that may be on the page
  dispatch(clearCallsViewFilters())

  const { analytics } = getState()
  const { own_organization_id } = getState().currentUser

  const filterQueryString = getFilterQueryString(analytics.filters, section)
  const filterBodyString = getFilterBodyString(analytics.filters, section)
  const [newStartDate, newEndDate] = findPreviousDateRangeFromCurrentFilters(
    analytics.filters.startDate,
    analytics.filters.endDate
  )

  const previousFilterString = getFilterQueryString(
    { ...analytics.filters, startDate: newStartDate, endDate: newEndDate },
    section
  )

  switch (section) {
    case 'checklist':
      await applyChecklistPageFilters(dispatch, filterQueryString, own_organization_id)
      break
    case 'deck':
      await applyDeckPageFilters(dispatch, filterQueryString, filterBodyString, own_organization_id)
      break
    case 'notifications':
      await applyNotificationsPageFilters(dispatch, filterQueryString, own_organization_id)
      break
    case 'postcall':
      await applyPostCallPageFilters(dispatch, filterQueryString, own_organization_id)
      break
    case 'usage':
      await applyUsagePageFilters(dispatch, filterQueryString)
      break
    case 'csv':
      break
    default:
      await applyOverviewPageFilters(dispatch, filterQueryString, previousFilterString)
  }
}

export const clearSavedFilter = () => (dispatch, getState) => {
  const { currentUser } = getState()
  const isBaltoAdmin = currentUser.organizationid === 1

  dispatch(setData({ selectedSavedFilterId: null }))

  if (isBaltoAdmin) {
    dispatch(clearFiltersMinusOrg())
  } else {
    dispatch(clearFilters())
  }

  dispatch(clearReportData())
}

export const setSavedFilterById =
  (filterId, shouldApplyFilters, section) => (dispatch, getState) => {
    const {
      savedFilters: { savedFilterList },
    } = getState()
    const analyticsSavedFilters = savedFilterList[ANALYTICS_FILTER_TYPE]
    const filterToApply = analyticsSavedFilters.find((filter) => filter.uuid === filterId)

    if (filterToApply?.filters) {
      dispatch(setData({ selectedSavedFilterId: filterId }))
      dispatch(setFilter(filterToApply.filters))

      // Set actual start/end times based on static range
      const staticRange = customStaticRanges.find(
        (staticRange) => staticRange.label.toLowerCase() === filterToApply.filters.dateRange
      )

      if (staticRange) {
        dispatch(setDateRangeFilter(staticRange.range()))
      }
    }

    if (shouldApplyFilters) {
      dispatch(applyFilters(section))
    }
  }

export const setDefaultSavedFilter = () => (dispatch, getState) => {
  const {
    savedFilters: { savedFilterList },
  } = getState()
  const analyticsSavedFilters = savedFilterList[ANALYTICS_FILTER_TYPE]

  // Find the default saved filter, if exists
  const defaultFilter = analyticsSavedFilters.find((filter) => filter.is_default)

  // Set the saved filter options if they exist
  if (defaultFilter) {
    dispatch(setSavedFilterById(defaultFilter.uuid))
  }
}

export const applyCallsViewFilters = (props) => async (dispatch, getState) => {
  const { deckItems, withEvents } = props

  dispatch(setLoading({ deckItemsByCall: true }))
  const {
    analytics: { filters },
  } = getState()
  const filterString = getFilterQueryString(filters, 'deck')
  const callsViewFilterString = queryString.stringify({
    entries: deckItems,
    with_events: withEvents,
  })
  dispatch(setCallsViewFilters({ entries: deckItems, with_events: withEvents }))

  try {
    const deckItemsByCallResponse = await fetchingAPI(
      `${apiService.reporting}/api/dynamic_prompt/items_by_call?${filterString}&${callsViewFilterString}`,
      'GET',
      dispatch
    )

    dispatch(
      setData({
        deckItemsByCall: deckItemsByCallResponse.calls_data,
        deckItemsByCallCursors: {
          olderCursor: deckItemsByCallResponse.older_cursor,
          newerCursor: deckItemsByCallResponse.newer_cursor,
        },
      })
    )
  } catch (err) {
    toast.error('Failed to apply calls view filters')
  } finally {
    dispatch(setLoading({ deckItemsByCall: false }))
  }
}

export const loadInitialData = () => async (dispatch, getState) => {
  const { organizationid: currentUserOrgId } = getState().currentUser

  await dispatch(fetchSavedFilters(ANALYTICS_FILTER_TYPE))

  dispatch(setDefaultSavedFilter())

  // not awaiting these because it can be in the process of loading while the report data is fetched
  dispatch(fetchAllData(currentUserOrgId))

  dispatch(setInitialLoadComplete())
}

// This old reporting endpoint passes the playbook config along in the body instead of
// fetching it on the backend using the config cid so we have to do all these extra API calls.
export const preFetchCSVExport = () => async (dispatch, getState) => {
  const {
    analytics: { filters },
  } = getState()
  const playbookCid = filters.playbooks[0].value

  try {
    const versions = await fetchingAPI(
      `${apiService.web}/api/configs/${playbookCid}/versions`,
      'GET',
      dispatch
    )
    const latestVersionId = versions[0].id

    const playbook = await fetchingAPI(
      `${apiService.web}/api/configs/${latestVersionId}`,
      'GET',
      dispatch
    )

    return playbook
  } catch (err) {
    return toast.error('Failed to fetch data for CSV export')
  }
}

export const fetchCSVExport = (reportType, selectedSections, playbook) => (dispatch, getState) => {
  const {
    analytics: { filters },
  } = getState()

  dispatch(setLoading({ exportCSVPage: true }))

  const body = JSON.stringify({
    config: playbook.config,
    callDuration: toNumber(filters.callDuration),
    configCid: filters.playbooks[0].value,
    startDate: moment(filters.startDate).startOf('day').format(),
    endDate: moment(filters.endDate).endOf('day').format(),
    selectedTags: filters.tags.map((tag) => tag.value),
    includeManagers: filters.includeManagers,
    organizationId: filters.organization,
    selectedSections,
  })

  const filename = `Balto_${reportType}_${moment(filters.startDate).format('YYYY-MM-DD')}-${moment(
    filters.endDate
  ).format('YYYY-MM-DD')}.csv`

  return fetchingAPI(`${apiService.web}/api/reports/csv/${reportType}`, 'POST', dispatch, body)
    .then((csvStream) => {
      // get stream reader from fetch response body stream
      const responseReader = csvStream.getReader()
      const streamProgress = { calls: 0, data: '' }
      // this function reads stream, then if done == true we download and clean up.
      // if not then we read value, update accumulators, and recursively call
      const readStream = () =>
        responseReader.read().then(({ value, done }) => {
          if (done) {
            const encodedUri = URL.createObjectURL(
              new Blob([streamProgress.data], { type: 'text/csv' })
            )
            const link = document.createElement('a')
            link.setAttribute('href', encodedUri)
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)

            dispatch(setData({ callsDownloaded: 'Complete' }))
            dispatch(setLoading({ exportCSVPage: false }))

            return null
          }
          // stream comes in as uint8array
          const decodedCsvData = new TextDecoder('utf-8').decode(value)
          streamProgress.data += decodedCsvData
          streamProgress.calls += decodedCsvData.split('\n').filter((call) => call !== '').length

          dispatch(setData({ callsDownloaded: streamProgress.calls }))

          return readStream()
        })

      readStream()
    })
    .catch((err) => {
      console.error('loadCsvData failed', err)
      if (err?.status === 422) {
        toast.error(
          'Not enough data, try a different playbook or longer date range. Playbook sections may not exist in the playbook selected.'
        )
      }

      dispatch(setLoading({ exportCSVPage: false }))
    })
}

export const fetchPromptsByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading({ prompts: true }))

  try {
    const url = `${apiService.reporting}/api/lightning_bolts/organization/${organizationId}`
    const rawPrompts = await fetchingAPI(url, 'GET', dispatch)
    const prompts = [
      ...rawPrompts.scorecard.map((scorecard) => ({
        ...scorecard,
        type: scorecard.scorecard_configs.reduce((acc, config) => {
          if (!acc.includes(config.type)) {
            acc.push(config.type)
          }
          return acc
        }, []),
        link: scorecard.scorecard_configs.map((scorecardConfig) => ({
          link: `/${scorecardConfig.type === 'copilot' ? 'qa-copilot' : 'coaching/scorecards'}/${scorecardConfig.id}`,
          label: scorecardConfig.name,
        })),
      })),
      ...rawPrompts.notetaker.map((notetaker) => ({
        ...notetaker,
        type: ['notetaker'],
        link: notetaker.playbooks.map((playbook) => ({
          link: `/playbooks/${playbook.id}`,
          label: playbook.name,
        })),
      })),
    ]

    dispatch(setData({ prompts }))
  } catch (err) {
    toast.error('Failed to fetch prompts')
  } finally {
    dispatch(setLoading({ prompts: false }))
  }
}
