import React from 'react'
import ReactSelect from 'react-select'
import classNames from 'classnames'
import { get } from 'lodash'

import { ErrorMessage } from '@/components/forms/ErrorMessage'

import { selectStyles, selectTheme } from '../../helpers/selectHelpers'

export const Select = ({
  label,
  isDisabled,
  disabled,
  wide,
  options,
  field: { name, value },
  form: { setFieldValue, touched, errors },
  onChange = () => {},
  required,
  description,
  defaultValue,
  ...props
}) => {
  const fieldError = get(errors, name)
  const fieldTouched = get(errors, touched)
  const hasError = !!fieldError && !!fieldTouched

  // Set initial default value if provided and value is not set
  React.useEffect(() => {
    if (defaultValue && !value) {
      setFieldValue(name, defaultValue.value)
    }
  }, [defaultValue, name, value])

  const handleFieldChange = (option, action) => {
    const fieldValue = option ? option.value : ''

    setFieldValue(name, fieldValue)
    if (onChange) {
      onChange(option, action)
    }
  }

  return (
    <div
      className={classNames('field', {
        [props.className]: props.className,
        required,
      })}
      data-testid={`${name}-select`}
    >
      {label && <label htmlFor={name}>{label}</label>}
      {description && <div className="form-caption">{description}</div>}
      <ReactSelect
        isClearable
        isSearchable
        closeMenuOnSelect
        escapeClearsValue={false}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        options={options}
        value={options.find((option) => option.value === value) || defaultValue || ''}
        onChange={handleFieldChange}
        isDisabled={isDisabled || disabled}
        selection
        clearable
        styles={selectStyles(wide, hasError)}
        theme={(theme) => selectTheme(theme)}
        {...props}
        inputId={name}
      />
      {hasError && <ErrorMessage content={fieldError} />}
    </div>
  )
}
