import React from 'react'
import classNames from 'classnames'
import { Button, Checkbox } from 'semantic-ui-react'

import './OrgHierarchySelector.scss'

export const OrgHierarchySelector = ({ currentUserOrgId, userOrgHierarchy }) => {
  const handleSwitchOrg = (orgID, orgName) => {
    // update local storage
    const user = JSON.parse(localStorage.getItem('User'))
    user.organizationid = orgID
    user.organization_name = orgName
    localStorage.setItem('User', JSON.stringify(user))

    // load the live-listen page (redux will be updated after loading with local storage user)
    // we go to live-listen page instead of just reloading current page since the user may be
    // at a resource they can no longer view from their new org, which causes errors
    window.location.assign('/live-listen')
  }

  const createHierarchy = (root) => {
    const activeOrg = root && root.organization_id === currentUserOrgId

    return (
      root && (
        <ul className="org-hierarchy-list" data-testid="org-hierarchy-list">
          <li key={root.organization_id}>
            <Button
              id={root.organization_id}
              onClick={() => {
                if (!activeOrg) {
                  handleSwitchOrg(root.organization_id, root.name)
                }
              }}
              className={classNames('org-hierarchy-button', { active: activeOrg })}
            >
              <Checkbox radio checked={activeOrg} />
              {root.name}
            </Button>
            {root?.children && <ul>{root.children.map((child) => createHierarchy(child))}</ul>}
          </li>
        </ul>
      )
    )
  }

  return <div>{createHierarchy(userOrgHierarchy)}</div>
}
