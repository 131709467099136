import React from 'react'
import {
  IconAdjustments,
  IconClipboardText,
  IconListCheck,
  IconNote,
  IconPackageExport,
  IconPhoneCall,
  IconRoad,
  IconScale,
  IconBulb,
} from '@tabler/icons-react'

// Sections
export const CALLS = 'calls'
export const QUALITY = 'quality'
export const GUIDANCE = 'guidance'
export const COACHING = 'coaching'
export const COMPLIANCE = 'compliance'
export const NOTES = 'notes'
export const ADMIN = 'admin'
export const DATA_EXPORT = 'data_export'
export const INSIGHTS = 'insights'

export const SIDEBAR_COLLAPSED_SECTIONS = 'sidebarCollapsedSections'

export const iconMap = {
  [CALLS]: <IconPhoneCall />,
  [QUALITY]: <IconListCheck />,
  [GUIDANCE]: <IconRoad />,
  [COACHING]: <IconClipboardText />,
  [COMPLIANCE]: <IconScale />,
  [NOTES]: <IconNote />,
  [ADMIN]: <IconAdjustments />,
  [DATA_EXPORT]: <IconPackageExport />,
  [INSIGHTS]: <IconBulb />,
}

export const callsLinks = [
  { path: '/call-explorer', label: 'Call Explorer' },
  { path: '/live-listen', label: 'Live Listen' },
  { path: '/playlists', label: 'Playlists' },
]

export const insightsLinks = [{ path: '/dynamic-insights', label: 'Dynamic Insights' }]
export const coachingLinks = [{ path: '/coaching/inbox', label: 'Coaching Inbox' }]
export const complianceLinks = [{ path: '/compliance/inbox', label: 'Compliance Inbox' }]

export const qualityLinks = [
  { path: '/qa-copilot/inbox', label: 'Inbox' },
  { path: '/qa-copilot/dashboard', label: 'Dashboard', exact: true },
  { path: '/qa-copilot/enterprise', label: 'Enterprise Dashboard' },
  { path: '/qa-copilot/view_scores', label: 'Scores' },
  { path: '/qa-copilot/activity', label: 'Activity' },
  { path: '/scorecards/dashboard', label: 'RTQ Dashboard', exact: true },
  { path: '/scorecards/view_scores', label: 'RTQ Scores' },
  { path: '/scorecards/activity', label: 'RTQ Activity' },
]

export const guidanceLinks = [
  { path: '/playbooks', label: 'Playbooks' },
  { path: '/reports', label: 'Reporting', exact: true },
  { path: '/reports/checklist', label: 'Checklist', nested: true },
  { path: '/reports/dynamic-prompt', label: 'Dynamic Prompt', nested: true },
  { path: '/reports/notifications', label: 'Notifications', nested: true },
  { path: '/reports/postcall', label: 'Post Call', nested: true },
  { path: '/reports/usage', label: 'Usage', nested: true },
  { path: '/leaderboards', label: 'Leaderboards' },
  { path: '/realtime_coaching/activity-log', label: 'RTC Activity Log' },
  { path: '/realtime_coaching/reports', label: 'RTC Reporting' },
]

export const adminLinks = [
  { path: '/qa-copilot', label: 'Copilot Scorecards', exact: true },
  { path: '/scorecards', label: 'RTQ Scorecards', exact: true },
  { path: '/coaching/scorecards', label: 'Coaching Scorecards', exact: true },
  { path: '/ai_usage', label: 'AI Usage' },
  { path: '/realtime_coaching/alerts', label: 'Real-Time Alerts' },
  { path: '/organizations', label: 'Organization Management' },
  { path: '/clear_transcripts_and_audio', label: 'Clear transcripts and audio' },
  { path: '/users', label: 'User Management' },
  { path: '/integrations', label: 'Integrations' },
  { path: '/keywords_repository', label: 'Keyword Repository' },
]

export const notesLinks = [{ path: '/realtime-notetaker', label: 'Notes' }]

export const dataExportLinks = [
  { path: '/feature-feedback', label: 'Feature Feedback' },
  { path: '/reports/csv', label: 'Playbook Export' },
  { path: '/qa-copilot/exports', label: 'Quality Scorecard Export' },
  { path: '/scorecards/exports', label: 'RTQ Scorecard Export' },
  { path: '/realtime_coaching/exports', label: 'RTC Export' },
]
