import produce from 'immer'
// Action Types
export const SET_ORGANIZATION = 'organizations/setOrganization'
export const SET_AUDIT_EVENTS = 'organizations/setAuditEvents'

// Action Creators
export const setOrganization = (payload) => ({ type: SET_ORGANIZATION, payload })
export const setOrganizationAuditEvents = (payload) => ({ type: SET_AUDIT_EVENTS, payload })

// State
export const initialState = {
  ai_library_model_cid: null,
  auditEvents: [],
  call_events_api_access: false,
  call_sentiment_upset_reason_enabled: false,
  call_summarization_enabled: false,
  custom_branding_display_name: null,
  custom_branding_enabled: false,
  custom_branding_theme: null,
  data_access_key: null,
  deleted: false,
  desktop_always_start_call: false,
  desktop_auth_key: null,
  desktop_enterprise_enabled: false,
  desktop_leaderboard_visibility: false,
  desktop_manual_config_selecting: false,
  desktop_visibility: false,
  id: null,
  leaderboard_include_managers: false,
  name: null,
  novel_content_disabled: false,
  phrase_matching_model_cid: null,
  playbook_additional_info: false,
  playbook_timer: false,
  production: false,
  qa_enabled: false,
  qa_copilot_enabled: false,
  qa_export: false,
  qa_reporting: false,
  realtime_coaching_access: false,
  remote_desktop_key: null,
  requires_compliance: false,
  salesforce_id: null,
  saml_metadata_url: null,
  save_audio: false,
  save_audio_ratio: null,
  show_summarization_notes: false,
  smart_knowledge_base_enabled: false,
  use_transcript_hyp: false,
  use_smart_phrase_shadow_mode_model: false,
  screen_capture_enabled: false,
  call_data_dump_enabled: false,
  interval_call_data_dump_enabled: false,
  uuid: '',
  audio_expiry_days: null,
  postcall_min_duration_minutes: null,
  postcall_max_duration_minutes: null,
}

// Reducer
export default function organizationReducer(state = initialState, action) {
  return produce(state, (newState) => {
    const { payload, type } = action

    switch (type) {
      case SET_ORGANIZATION:
        newState = payload
        return newState
      case SET_AUDIT_EVENTS:
        newState.auditEvents = payload
        return newState
      default:
        return newState
    }
  })
}
