import React, { useEffect, useState } from 'react'
import { Field, useField } from 'formik'
import { Popup } from 'semantic-ui-react'

import { TextField, TextArea, Select, Checkbox, Slider } from '@/components/forms/formik'
import { FieldWithLabel } from '@/components/forms/formik/FieldWithLabel'
import { KeywordSelect } from '@/components/forms/formik/selects/KeywordSelect'

import { FieldWarning } from './FieldWarning'
import { SmartPhraseTrigger } from '../modules/categories/SmartPhraseTrigger'

import { sides, accessors } from '../playbook.helpers'

export const PlaybookEntryFormTriggers = ({
  loading,
  values,
  dispatch,
  triggerType,
  formikProps,
  readOnly,
  sideDisabled,
  sideHidden,
  triggerHidden,
  entryId,
  accessor,
  updateTriggerWithAi,
}) => {
  if (!triggerType || triggerType === 'none') {
    return null
  }

  const [durationField, , durationHelpers] = useField('display_duration_seconds')
  const [unitField, , unitHelpers] = useField('display_duration_unit')
  const [initialConversionDone, setInitialConversionDone] = useState(false)

  const formatNumber = (value) => {
    return Number(value) % 1 === 0 ? Math.floor(value).toString() : value.toFixed(2)
  }

  useEffect(() => {
    if (!initialConversionDone && durationField.value) {
      const valueInSeconds = parseFloat(durationField.value)
      if (!valueInSeconds) return

      let timeValue = valueInSeconds
      let unit = unitField.value

      if ((timeValue === 3600 || timeValue >= 60) && unit !== 'minutes') {
        const minutes = Math.floor(timeValue / 60)
        const seconds = timeValue % 60
        timeValue = minutes + seconds / 60
        unit = 'minutes'
      }

      setTimeout(() => {
        unitHelpers.setValue(unit)
        durationHelpers.setValue(formatNumber(timeValue))
        setInitialConversionDone(true)
      }, 0)
    }
  }, [durationField.value, initialConversionDone])

  const handleUnitChange = (selectedOption) => {
    const currentValue = parseFloat(durationField.value)
    if (!currentValue) return

    const newUnit = selectedOption.value
    const oldUnit = unitField.value

    if (newUnit === 'minutes' && oldUnit === 'seconds') {
      const minutes = currentValue / 60
      durationHelpers.setValue(formatNumber(minutes))
    } else if (newUnit === 'seconds' && oldUnit === 'minutes') {
      const seconds = currentValue * 60
      durationHelpers.setValue(formatNumber(seconds))
    }

    unitHelpers.setValue(newUnit)
  }

  const getMaxValue = () => (unitField.value === 'minutes' ? 60 : 3600)

  return (
    <>
      {!sideHidden && (
        <Popup
          content="This category is designed for this side of the conversation."
          position="right center"
          disabled={!sideDisabled}
          trigger={
            <div className="field">
              <Field
                required
                label="Conversation Side"
                name="trigger.side"
                component={Select}
                options={sides}
                isClearable={false}
                disabled={sideDisabled}
                description="Define which side of the conversation will trigger the current Dynamic Prompt."
              />
            </div>
          }
        />
      )}
      {triggerType === 'transcription_classifier' && (
        <>
          <FieldWithLabel
            required
            label="Topics"
            name="trigger.krid"
            component={KeywordSelect}
            isClearable={false}
            disabled={readOnly}
          />
          {!readOnly && <FieldWarning accessor={accessor} field="trigger.krid" entryId={entryId} />}
        </>
      )}
      {triggerType === 'keywords' && (
        <>
          <Field
            required
            label="Trigger Keywords"
            name="trigger.phrases"
            placeholder="Enter keywords and phrases, separated by commas"
            component={TextArea}
            disabled={readOnly}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault()
              }
            }}
          />
          {!readOnly && (
            <FieldWarning
              accessor={accessor}
              field="trigger.phrases"
              entryId={entryId}
              updateTriggerWithAi={updateTriggerWithAi}
            />
          )}
        </>
      )}
      {triggerType === 'smart_phrases' && (
        <SmartPhraseTrigger
          loading={loading}
          values={values}
          readOnly={readOnly}
          dispatch={dispatch}
          formikProps={formikProps}
        />
      )}
      {triggerType === 'talk_ratio' && (
        <Field
          required
          label="Talk Ratio"
          name="trigger.ratio"
          placeholder="25"
          component={TextField}
          type="number"
          min="1"
          disabled={readOnly}
        />
      )}
      {!triggerHidden && (
        <div>
          <label>Display Duration</label>
          <div className="form-caption">
            Set how long the Dynamic Prompt stays expanded and visible after being triggered for the
            agent.
          </div>
          <div className="fields duration-fields">
            <div className="four wide field">
              <Field
                name="display_duration_seconds"
                placeholder="180"
                component={TextField}
                type="number"
                min={unitField.value === 'minutes' ? '0.01' : '1'}
                max={getMaxValue()}
                disabled={readOnly}
                step={unitField.value === 'minutes' ? '0.01' : '1'}
                onBlur={(e) => {
                  if (!e.target.value) {
                    durationHelpers.setValue(180)
                  }
                }}
              />
            </div>
            <div className="four wide field">
              <Field
                name="display_duration_unit"
                component={Select}
                options={[
                  { label: 'Minutes', value: 'minutes' },
                  { label: 'Seconds', value: 'seconds' },
                ]}
                defaultValue={{ label: 'Seconds', value: 'seconds' }}
                isSearchable={false}
                isClearable={false}
                onChange={handleUnitChange}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    height: '38px',
                    minHeight: '38px',
                  }),
                  valueContainer: (baseStyles) => ({
                    ...baseStyles,
                    height: '38px',
                    padding: '0 8px',
                  }),
                }}
              />
            </div>
          </div>
        </div>
      )}
      {!triggerHidden && (
        <Field
          label="Trigger Active"
          name="trigger"
          component={Slider}
          formikProps={formikProps}
          disabled={readOnly}
        />
      )}
      {(accessor === accessors.DYNAMIC_PROMPT || accessor === accessors.POSTCALL) && (
        <Field
          label="Trigger Once"
          name="trigger.max_triggers"
          component={Checkbox}
          inline
          disabled={readOnly}
        />
      )}
    </>
  )
}
