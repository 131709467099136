import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import { useSelector } from 'react-redux'

import { MultiSelect } from '@/components/forms/MultiSelect'

export const TaskOnboardingAgentSelect = ({ handleSubmitOnboarding }) => {
  const [selectedAgents, setSelectedAgents] = useState([])
  const { data, loading } = useSelector((state) => state.tasks)

  return (
    <>
      <header className="mb">
        <h2>Choose Your Agents</h2>
        <p>Choose which agents appear in your inbox. You can update this list anytime.</p>
      </header>

      <div className="label-style">Agents</div>
      <div className="modal-open-dropdown">
        <MultiSelect
          isSelectAll
          placeholder="Begin typing to search for agents..."
          label="Agent"
          options={data.agents}
          optionsLength={data.agents.length}
          loading={loading.agents}
          value={selectedAgents}
          onChange={(value) => {
            setSelectedAgents(value)
          }}
          fixedWidth
          condenseOptions={false}
          showFullOptions
        />
      </div>
      <div className="mt medium-gap flex-end">
        <Button
          data-testid="skip-for-now-button"
          secondary
          onClick={() => handleSubmitOnboarding()}
        >
          Skip For Now
        </Button>
        <Button
          data-testid="complete-onboarding-button"
          primary
          onClick={() => handleSubmitOnboarding(selectedAgents)}
        >
          Finish Setup
        </Button>
      </div>
    </>
  )
}
