import { isNumber } from 'lodash'
import queryString from 'query-string'

export const HARD_SELECTED_EVENT_ID = 'hard-selected-event'
export const EVENT_VIEWER_ID = 'event-viewer'

export const SECTION_NAMES = {
  postcall: 'Post Call',
  deck: 'Dynamic Prompt',
  checklist: 'Checklist',
  notification: 'Notification',
  // skb: 'Smart Knowledge Base',
  keywords: 'Keyword',
}

export const getContextFromEvent = (event) => {
  const { section, category, item, decklist_item, criteria_name, scorecard_name } = event
  let contextStr = section
  // the api returns it without the s but we store it in valid
  // schema with it. want to update the api but there's some side
  // effects i would want to look out for first
  if (section === 'notification') {
    contextStr = 'notifications'
  }

  if (category) {
    contextStr += ` - ${category}`
  }
  if (item) {
    contextStr += ` - ${item}`
  }
  if (decklist_item) {
    contextStr += ` - ${decklist_item}`
  }
  if (criteria_name && scorecard_name) {
    contextStr = `QA Copilot - ${scorecard_name} - ${criteria_name}`
  }
  if (criteria_name && !scorecard_name) {
    contextStr = `keywords - ${criteria_name}`
  }

  return contextStr
}

export const calculateScores = (score) => {
  if (score.possible_score === 0) {
    return 'N/A'
  }

  const scorePercent = score.aggregate_score / score.possible_score
  const totalScore = scorePercent.toLocaleString('en', {
    style: 'percent',
    minimumFractionDigits: 2,
  })

  return totalScore
}

export const calculateGrade = (score) => {
  const scorePercent = score.aggregate_score / score.possible_score

  if (scorePercent * 100 > score.exceeds_threshold) {
    return { success: true }
  }
  if (scorePercent * 100 > score.meets_threshold) {
    return { caution: true }
  }
  if (scorePercent * 100 > score.improvement_threshold) {
    return { warning: true }
  }

  return { critical: true }
}

export const getInitialCriteriaValues = (score, userId) => {
  return score.section_scores
    .flatMap((sectionScore) =>
      sectionScore.measure_scores.flatMap((measureScore) =>
        measureScore.criteria_scores.flatMap((criteriaScore) => ({
          uuid: criteriaScore.uuid,
          score: criteriaScore.score,
          // Find a note for the criteria by the user
          note: criteriaScore?.notes?.find((note) => note.created_by === userId)?.note || '',
        }))
      )
    )
    .reduce((acc, { uuid, score, note }) => {
      // unfortunately due to the way radio groups work, the id needs to be the value so each value
      // needs to be unique for the entire form :(
      acc[uuid] = score
      acc[`${uuid}-note`] = note

      return acc
    }, {})
}

export const CALL_EXPLORER_CRITERIA_FILTER_ENUMS = {
  ALL: 'all',
  INCOMPLETE: 'incomplete',
  COMPLETE: 'complete',
  PASSING: 'passing',
  FAILING: 'failing',
  DISPUTES: 'disputes',
  NA: 'na',
}

export const filterCriteriaScore = (criteriaScore, selectedCriteriaFilter) => {
  if (selectedCriteriaFilter === CALL_EXPLORER_CRITERIA_FILTER_ENUMS.ALL) {
    return true
  }
  if (selectedCriteriaFilter === CALL_EXPLORER_CRITERIA_FILTER_ENUMS.PASSING) {
    return criteriaScore.score === 1
  }
  if (selectedCriteriaFilter === CALL_EXPLORER_CRITERIA_FILTER_ENUMS.FAILING) {
    return criteriaScore.score === 0
  }
  if (selectedCriteriaFilter === CALL_EXPLORER_CRITERIA_FILTER_ENUMS.INCOMPLETE) {
    return !isNumber(criteriaScore.score)
  }
  if (selectedCriteriaFilter === CALL_EXPLORER_CRITERIA_FILTER_ENUMS.NA) {
    return criteriaScore.score === -1
  }
  if (selectedCriteriaFilter === CALL_EXPLORER_CRITERIA_FILTER_ENUMS.COMPLETE) {
    return isNumber(criteriaScore.score)
  }
  if (selectedCriteriaFilter === CALL_EXPLORER_CRITERIA_FILTER_ENUMS.DISPUTES) {
    return criteriaScore?.dispute?.status === 'pending'
  }
  return true
}

export const formatTime = (anchorTime) => {
  const totalSeconds = Math.floor(anchorTime) // Drop the milliseconds

  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds - hours * 3600) / 60)
  const seconds = totalSeconds - hours * 3600 - minutes * 60

  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')
  const formattedSeconds = seconds.toString().padStart(2, '0')

  let formattedTime = ''
  if (formattedHours !== '00') {
    formattedTime = `${formattedHours}:`
  }
  formattedTime += `${formattedMinutes}:${formattedSeconds}`

  return formattedTime
}

export const formatScorecardCriteriaType = (criteriaScore) => {
  if (criteriaScore.manual) {
    return 'Manual Evaluation'
  }
  if (criteriaScore.criteria_type === 'ai') {
    return 'Copilot'
  }
  if (criteriaScore.criteria_type === 'automated') {
    return 'Automated'
  }
  return null
}

export const CALL_EXPLORER_TAB_ENUMS = {
  SUMMARY: 'summary',
  DETAILS: 'details',
  EVENTS: 'events',
  QA_SCORES: 'qa_scores',
  ACTIVITY: 'activity',
  COMMENTS: 'comments',
}

export const parseCallExplorerParams = (location) => {
  const { search } = location
  const parsed = queryString.parse(search)
  return parsed
}

export const updateCallExplorerParams = (location, history, params, paramsToRemoveList = []) => {
  const parsedParams = parseCallExplorerParams(location)
  const updatedParams = { ...parsedParams, ...params }
  paramsToRemoveList.forEach((param) => {
    delete updatedParams[param]
  })
  const search = queryString.stringify(updatedParams)
  history.push({ search })
}

export const removeCallExplorerParams = (location, history, paramsList) => {
  const parsedParams = parseCallExplorerParams(location)
  const updatedParams = { ...parsedParams }
  paramsList.forEach((param) => {
    delete updatedParams[param]
  })
  const search = queryString.stringify(updatedParams)
  history.push({ search })
}

export const canUserSave = (editRTQA, editQaCopilot, scorecardType) => {
  return (
    (scorecardType === 'automated' && editRTQA) || (scorecardType !== 'automated' && editQaCopilot)
  )
}
