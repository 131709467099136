import React, { createContext, useRef, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { setCallExplorerData } from '@/reducers/callSearch/callSearch.redux'
import { updateMediaPlayer } from '@/reducers/media/media.redux'

const AudioPlayerContext = createContext()

export const AudioPlayerProvider = ({ children }) => {
  const audioRef = useRef(null)
  const dispatch = useDispatch()
  const retryRef = useRef(0)

  const changeAudioPlaybackRate = (speed) => {
    if (audioRef.current) {
      audioRef.current.playbackRate = speed
    }
  }

  const jumpAudioTo = (time) => {
    if (audioRef.current) {
      audioRef.current.currentTime = time
    }
  }

  const playAudio = () => {
    if (audioRef.current && audioRef.current.paused) {
      audioRef.current.play()
    }
  }

  const pauseAudio = () => {
    if (audioRef.current && !audioRef.current.paused) {
      audioRef.current.pause()
    }
  }

  const changeAudioVolume = (volume) => {
    if (audioRef.current) {
      audioRef.current.volume = volume
    }
  }

  const addAudioEvent = (eventType, callback) => {
    if (audioRef.current) {
      audioRef.current.addEventListener(eventType, callback)
    }
  }

  const removeAudioEvent = (eventType, callback) => {
    if (audioRef.current) {
      audioRef.current.removeEventListener(eventType, callback)
    }
  }

  const reloadAudio = () => {
    if (audioRef.current) {
      if (retryRef.current < 3) {
        console.error(`Reloading audio... Attempt ${retryRef.current + 1}`)
        audioRef.current.load()
        retryRef.current += 1

        const onCanPlayThrough = () => {
          console.error('Audio is ready to play')
          dispatch(setCallExplorerData({ audioError: false }))
          dispatch(updateMediaPlayer({ audioError: false }))
          retryRef.current = 0
          audioRef.current.removeEventListener('canplaythrough', onCanPlayThrough)
        }

        audioRef.current.addEventListener('canplaythrough', onCanPlayThrough)

        if (audioRef.current.readyState >= 3) {
          console.error('Audio is already in a playable state')
          dispatch(setCallExplorerData({ audioError: false }))
          dispatch(updateMediaPlayer({ audioError: false }))
          retryRef.current = 0
        }
      } else {
        console.error('Maximum reload attempts reached. Unable to reload audio.')
      }
    }
  }

  const handleAudioError = () => {
    if (audioRef.current) {
      console.error('Audio error encountered. Attempting to reload...')
      reloadAudio()
    }
  }

  React.useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('error', handleAudioError)
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('error', handleAudioError)
      }
    }
  }, [])

  return (
    <AudioPlayerContext.Provider
      value={{
        audioRef,
        playAudio,
        pauseAudio,
        jumpAudioTo,
        changeAudioPlaybackRate,
        changeAudioVolume,
        addAudioEvent,
        removeAudioEvent,
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  )
}

export const useAudioPlayer = () => {
  return useContext(AudioPlayerContext)
}
