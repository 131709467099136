import React from 'react'
import classNames from 'classnames'

import { formatDuration } from '@/utils/helpers'

import { HARD_SELECTED_EVENT_ID } from './helpers'

export const DeckItemEventBlock = ({
  dataTestId,
  timestamp,
  item,
  showTimestamp,
  isHardSelectedEvent,
  onClick,
}) => {
  return (
    <div
      id={isHardSelectedEvent ? HARD_SELECTED_EVENT_ID : ''}
      className={classNames('deck-item-event-block', {
        'hard-selected': isHardSelectedEvent,
      })}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <div
        className={classNames('deck-item-start-time', { 'hide-time': !showTimestamp })}
        data-testid="deck-item-timestamp"
      >
        {formatDuration(timestamp)}
      </div>
      <div className="deck-item-event-content-wrapper">
        <div className={classNames('deck-item-event-content', { selected: isHardSelectedEvent })}>
          {item}
        </div>
      </div>
    </div>
  )
}
