import produce from 'immer'

// Action Types
export const SET_LOADING = 'media/setLoading'
export const UPDATE_MEDIA_PLAYER = 'media/updateMediaPlayer'
export const UPDATE_SETTINGS = 'media/updateSettings'
export const TOGGLE_MEDIA_PLAYER_PLAYING = 'media/toggleMediaPlayerPlaying'
export const RESET_MEDIA_PLAYER = 'media/resetMediaPlayer'
export const PAUSE_MEDIA_PLAYER = 'media/pauseMediaPlayer'

// Action Creators
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const updateMediaPlayer = (payload) => ({ type: UPDATE_MEDIA_PLAYER, payload })
export const updateSettings = (payload) => ({ type: UPDATE_SETTINGS, payload })
export const toggleMediaPlayerPlaying = (payload) => ({ type: TOGGLE_MEDIA_PLAYER_PLAYING, payload }) // prettier-ignore
export const resetMediaPlayer = (payload) => ({ type: RESET_MEDIA_PLAYER, payload })
export const pauseMediaPlayer = () => ({ type: PAUSE_MEDIA_PLAYER })

// State
export const initialState = {
  mediaPlayer: {
    callId: null,
    isPlaying: false,
    duration: 0,
    progress: 0,
    audioUrl: null,
    audioStatus: 'not_available',
    audioUrlExpiration: 0,
    audioError: false,
    videoUrls: [],
    videoStatus: 'not_available',
    videoError: false,
    autoplay: false,
    hardSelectedEvent: null,
    skipSilence: false,
  },
  settings: {
    volume: 1,
    playbackRate: 1, // preserve playback rate from local storage between calls/refresh so it doesn't get reset
  },
  loading: {
    mediaPlayerByCallId: null,
  },
}

// Reducer
export default function audioReducer(state = initialState, action) {
  return produce(state, (newState) => {
    const { payload } = action

    switch (action.type) {
      case SET_LOADING:
        newState.loading = { ...state.loading, ...payload }
        break
      case UPDATE_MEDIA_PLAYER:
        newState.mediaPlayer = { ...state.mediaPlayer, ...payload }
        break
      case UPDATE_SETTINGS:
        newState.settings = { ...state.settings, ...payload }
        break
      case TOGGLE_MEDIA_PLAYER_PLAYING:
        if (state.mediaPlayer.audioUrl && state.mediaPlayer.callId) {
          newState.mediaPlayer = { ...state.mediaPlayer, isPlaying: !state.mediaPlayer.isPlaying }
        } else {
          newState.mediaPlayer = initialState.mediaPlayer
        }
        break
      case RESET_MEDIA_PLAYER:
        newState.mediaPlayer = initialState.mediaPlayer
        break
      case PAUSE_MEDIA_PLAYER:
        newState.mediaPlayer = { ...state.mediaPlayer, isPlaying: false }
        break
      default:
        break
    }
  })
}
