import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import { Loader } from 'semantic-ui-react'
import { IconSearch, IconStarFilled, IconCheck } from '@tabler/icons-react'
import pluralize from 'pluralize'

export const SearchableDropdownResults = ({
  triggerRef,
  setOpen,
  options,
  handleSelect,
  loading,
  inputProps,
  position,
  hideSearch,
  selected,
  actions,
  pinnedAccessor,
  showResultsHeader,
}) => {
  const dropdownRef = useRef(null)
  const [searchQuery, setSearchQuery] = useState('')

  const handleUpdateSearchQuery = (event) => {
    setSearchQuery(event.target.value)
  }

  const regex = new RegExp(searchQuery.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i')
  const isMatch = (result) => regex.test(result)

  const results = options.filter((result) => {
    if (!searchQuery) {
      return true
    }

    return isMatch(result.label)
  })

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (triggerRef) {
        if (
          dropdownRef.current &&
          triggerRef.current &&
          !dropdownRef.current.contains(event.target) &&
          !triggerRef.current.contains(event.target)
        ) {
          setOpen(false)
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setOpen(false)
        }
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [triggerRef, dropdownRef])

  return (
    <div className={classNames('searchable-dropdown-container', position)} ref={dropdownRef}>
      {!hideSearch && (
        <div className="searchable-dropdown-search">
          <div className="searchable-dropdown-search-input search-input">
            <input
              data-testid="searchable-dropdown-input"
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleUpdateSearchQuery}
              disabled={loading}
              autoFocus
              {...inputProps}
            />
            <IconSearch />
          </div>
          {!isEmpty(actions) && (
            <div className="searchable-dropdown-actions">
              {actions.map((action) => (
                <button
                  key={action.key}
                  data-testid={`searchable-dropdown-action-${action.key}`}
                  type="button"
                  className="searchable-dropdown-action"
                  onClick={(event) => {
                    action.fn(event)
                    setOpen(false)
                  }}
                >
                  {action.label}
                </button>
              ))}
            </div>
          )}
        </div>
      )}
      <div className="searchable-dropdown-menu">
        <div className="searchable-dropdown-results">
          {loading ? (
            <div>
              <Loader active data-testid="searchable-dropdown-loader" />
            </div>
          ) : !isEmpty(results) ? (
            <div>
              {showResultsHeader && (
                <header className="searchable-dropdown-results-header">
                  <div>{pluralize('result', results.length, true)}</div>
                  <div>
                    {!!selected && (
                      <button
                        type="button"
                        className="text-button"
                        onClick={() => handleSelect(null)}
                      >
                        Clear Filter
                      </button>
                    )}
                  </div>
                </header>
              )}
              {results.map((option) => {
                let searchedLabel = option.label
                const isSelected = option.value === selected || option.selected

                if (searchQuery) {
                  const highlightStart = option.label.search(regex)

                  if (highlightStart !== -1) {
                    const highlightEnd = highlightStart + searchQuery.length

                    const beginning = searchedLabel.slice(0, highlightStart)
                    const highlighted = searchedLabel.slice(highlightStart, highlightEnd)
                    const end = searchedLabel.slice(highlightEnd)

                    searchedLabel = (
                      <>
                        {beginning && <span>{beginning}</span>}
                        {highlighted && <span>{highlighted}</span>}
                        {end && <span>{end}</span>}
                      </>
                    )
                  }
                }

                return (
                  <div
                    key={option.value}
                    className="searchable-dropdown-result"
                    data-testid="searchable-dropdown-result"
                    onClick={() => {
                      handleSelect(option.value)
                    }}
                  >
                    <div className="flex-align-center small-gap">
                      {option[pinnedAccessor] && (
                        <IconStarFilled className="icon-svg status-warning" />
                      )}
                      <div>{searchedLabel}</div>
                    </div>

                    {isSelected && (
                      <IconCheck
                        className="icon-svg status-brand"
                        data-testid={`selected-item-${option.value}`}
                      />
                    )}
                  </div>
                )
              })}
            </div>
          ) : (
            <div className="searchable-dropdown-no-results">
              <p className="muted-text">No results</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
