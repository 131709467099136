import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import { startOfDay, subDays } from 'date-fns'

import { submitCoachingRequest } from '@/reducers/tasks/tasks.actions'
import { CoachingSelfServiceForm } from './CoachingSelfServiceForm'

import './CoachingSelfService.scss'

export const CoachingSelfService = ({
  fetchTasksWithFilters,
  handleOpenTaskDrawer,
  suggestedAgents,
}) => {
  const dispatch = useDispatch()
  const { organizationid: currentUserOrgId } = useSelector((state) => state.currentUser)
  const { loading, data } = useSelector((state) => state.tasks)
  const startDate = subDays(new Date(), 29)
  const endDate = startOfDay(new Date())
  const initialValues = {
    question: '',
    agent: null,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  }

  const handleSubmitCoachingRequest = async (values) => {
    const payload = {
      question: values.question,
      organization_id: currentUserOrgId,
      start_date: new Date(values.startDate).toISOString(),
      end_date: new Date(values.endDate).toISOString(),
      agents: [values.agent],
      call_duration: 1,
    }

    dispatch(submitCoachingRequest(payload, fetchTasksWithFilters, handleOpenTaskDrawer))
  }

  return (
    <div>
      <section className="coaching-selfservice" data-testid="coaching-selfservice">
        <Formik initialValues={initialValues} onSubmit={handleSubmitCoachingRequest}>
          {({ values, handleSubmit, setFieldValue }) => {
            return (
              <CoachingSelfServiceForm
                initialValues={initialValues}
                values={values}
                loading={loading}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                suggestedAgents={suggestedAgents}
                data={data}
              />
            )
          }}
        </Formik>
      </section>
    </div>
  )
}
