import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Menu, Loader } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import queryString from 'query-string'
import { IconBoltFilled } from '@tabler/icons-react'

import {
  COACHING_INBOX,
  COMPLETED,
  COMPLIANCE_INBOX,
  EXPIRED,
  IGNORED,
  INBOX,
  QA_INBOX,
  READY_FOR_REVIEW,
} from '@/reducers/tasks/tasks.constants'
import { fetchTasks, fetchTaskById, patchTask } from '@/reducers/tasks/tasks.actions'
import { setTask, setSavedFilterId } from '@/reducers/tasks/tasks.redux'
import { FilterTypeSort } from '@/components/filters/types/FilterTypeSort'
import { FilterTypeDropdown } from '@/components/filters/types/FilterTypeDropdown'
import { AdvancedTablePagination } from '@/components/tables/AdvancedTable/AdvancedTablePagination'
import { usePagination } from '@/utils/hooks/usePagination'
import { INBOX_FILTER_TYPE } from '@/reducers/savedFilters/savedFilters.constants'
import { resetMediaPlayer } from '@/reducers/media/media.redux'
import { determineGradient } from '../helpers'

import { TaskCard } from './components/TaskCard'
import {
  taskTypeOptions,
  inboxSort,
  TODO,
  ARCHIVE,
  DEFAULT_SORT_CONFIG,
  getSortByOptions,
} from './inbox.helpers'
import { SavedFilters } from '../savedFilters/SavedFilters'
import NoData from '../NoData'
import { CoachingSelfService } from './components/CoachingSelfService'
import { Task } from './components/Task'

import './Inbox.scss'

export const Inbox = ({ inboxType, title }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { loading, data, tasks, selectedSavedFilterId } = useSelector((state) => state.tasks)
  const { savedFilterList } = useSelector((state) => state.savedFilters)
  const { user_id: currentUserId } = useSelector((state) => state.currentUser)
  const [taskStatus, setTaskStatus] = useState('')
  const [taskCreatedBy, setTaskCreatedBy] = useState('')
  const [sortConfig, setSortConfig] = useState(DEFAULT_SORT_CONFIG)
  const [openTab, setOpenTab] = useState(TODO)
  const { search } = location
  const sortByOptions = getSortByOptions(inboxType)

  const todoTasks = inboxSort(
    tasks.filter((task) => task.status === READY_FOR_REVIEW),
    sortConfig
  )
  const filteredTodoTasks = todoTasks.filter((task) => {
    if (taskCreatedBy === 'my') {
      return task.created_by === currentUserId
    }
    return true
  })
  const archivedTasks = inboxSort(
    tasks.filter((task) => [IGNORED, EXPIRED, COMPLETED].includes(task.status)),
    sortConfig
  )
  const filteredArchivedTasks = archivedTasks.filter((task) =>
    taskStatus ? task.status === taskStatus : true
  )
  const todoPagination = usePagination(filteredTodoTasks, 8)
  const archivePagination = usePagination(filteredArchivedTasks, 8)
  const currentlyAppliedFilter = savedFilterList[INBOX_FILTER_TYPE]?.find(
    (filter) => filter.uuid === selectedSavedFilterId
  )
  const agentsWithColors = data.agents.map((agent, index) => ({
    ...agent,
    color: determineGradient(index),
  }))
  const suggestedAgentsWithColors = currentlyAppliedFilter
    ? currentlyAppliedFilter.filters.agents.map((agent) => ({
        ...agent,
        color: agentsWithColors.find((a) => a.value === agent.value)?.color,
      }))
    : []

  const handleSelectSavedFilter = (value) => {
    if (!value) {
      dispatch(setSavedFilterId(null))
    } else {
      dispatch(setSavedFilterId(value))
    }
  }

  const fetchTasksWithFilters = () => {
    if (currentlyAppliedFilter) {
      dispatch(
        fetchTasks(
          inboxType,
          currentlyAppliedFilter.filters.agents.map((agent) => agent.value),
          currentlyAppliedFilter.filters.tags.map((tag) => tag.value)
        )
      )
    } else {
      dispatch(fetchTasks(inboxType))
    }
  }

  const handleUpdateTask = async (taskId, status, handleClose) => {
    await dispatch(patchTask(taskId, { status }, inboxType))

    fetchTasksWithFilters()

    if (handleClose) {
      handleClose()
    }
  }

  const handleOpenTaskDrawer = (task) => {
    dispatch(setTask(task))

    const search = queryString.stringify({ id: task.id })

    history.push({ search })
  }

  const handleCloseTaskDrawer = () => {
    dispatch(setTask(null))
    dispatch(resetMediaPlayer())

    history.push({})
  }

  const inboxes = [
    {
      id: TODO,
      title: 'Inbox',
      tasks: filteredTodoTasks,
      pagination: todoPagination,
      secondaryAction: 'Dismiss',
      primaryAction: 'View Details',
    },
    {
      id: ARCHIVE,
      title: 'Archive',
      tasks: filteredArchivedTasks,
      pagination: archivePagination,
      secondaryAction: 'Reopen',
      primaryAction: 'View Details',
    },
  ]

  const getEmptyState = () => {
    if (inboxType === COACHING_INBOX) {
      return <p>Select an agent to generate a coaching session.</p>
    }

    if (inboxType === COMPLIANCE_INBOX) {
      return (
        <p>
          Review more calls in <Link to="/call-explorer">Call Explorer</Link>
        </p>
      )
    }

    return "You're all caught up! As more quality tasks come in, they'll appear here. In the meantime, you can review past tasks in the Archive."
  }

  const currentTab = inboxes.find((inbox) => inbox.id === openTab)

  useEffect(() => {
    // Fetch task on initial load if id is in query params
    const params = queryString.parse(search)
    if (params.id) {
      dispatch(fetchTaskById(params.id, handleOpenTaskDrawer, handleCloseTaskDrawer))
    }
  }, [])

  useEffect(() => {
    fetchTasksWithFilters()
  }, [currentlyAppliedFilter])

  const getIdForTask = (task) => {
    let generatedBy

    if (task.scorecard_criteria_scid) {
      generatedBy = 'scorecard'
    } else if (task.prompt_id) {
      generatedBy = 'button'
    } else if (!task.ai_generated) {
      generatedBy = 'manual'
    } else {
      generatedBy = ''
    }

    return `${task.type}-${generatedBy}`
  }

  return (
    <>
      <header className="page-header">
        <h1 className="inbox-title" data-testid="inbox-title">
          <div className="icon-container__square" style={{ background: 'var(--gradient-brand' }}>
            <IconBoltFilled />
          </div>
          {title}
        </h1>
        <div className="flex-align-center medium-gap">
          <SavedFilters
            filterType={INBOX_FILTER_TYPE}
            noFiltersTitle="No Filters"
            noFiltersMessage="You have no filters"
            noFiltersButtonLabel="Create Filter"
            filterFormOptions={['name', 'agents', 'tags', 'isDefault']}
            selectedSavedFilterId={selectedSavedFilterId}
            handleSelectSavedFilter={handleSelectSavedFilter}
          />
        </div>
      </header>
      {inboxType === COACHING_INBOX && (
        <CoachingSelfService
          handleOpenTaskDrawer={handleOpenTaskDrawer}
          fetchTasksWithFilters={fetchTasksWithFilters}
          suggestedAgents={suggestedAgentsWithColors}
        />
      )}
      <div>
        <Menu pointing secondary className="tab-navigation no-margin">
          {inboxes.map((tab) => {
            return (
              <Menu.Item
                key={tab.id}
                data-testid={`tab-${tab.id}`}
                active={openTab === tab.id}
                onClick={() => {
                  setOpenTab(tab.id)
                }}
              >
                <span>{tab.title}</span>
              </Menu.Item>
            )
          })}
        </Menu>

        {loading[INBOX] ? (
          <div className="empty">
            <Loader inline active data-testid="loader" />
          </div>
        ) : (
          <div className="inbox-tasklist" data-testid="inbox-tasklist">
            <div className="flex-align-start medium-gap">
              <FilterTypeSort
                label="Sort By"
                options={sortByOptions}
                sortConfig={sortConfig}
                updateSortConfig={(updatedSortConfig) => {
                  setSortConfig(updatedSortConfig)
                }}
                data-testid="inbox-sort-by"
              />
              {openTab === ARCHIVE ? (
                <FilterTypeDropdown
                  accessor="status"
                  label="Status"
                  value={taskStatus}
                  options={taskTypeOptions}
                  updateSearch={(_, updatedValue) => {
                    setTaskStatus(updatedValue)
                  }}
                  handleRemoveFilter={() => {
                    setTaskStatus('')
                  }}
                  showAllOnEmpty
                  showAllOnEmptyLabel="All"
                  isClearable={!!taskStatus}
                  data-testid="inbox-filter-created-by"
                />
              ) : (
                inboxType !== QA_INBOX && (
                  <FilterTypeDropdown
                    accessor="created_by"
                    label="Created By"
                    value={taskCreatedBy}
                    options={[{ value: 'my', label: 'Me' }]}
                    handleRemoveFilter={() => {
                      setTaskCreatedBy('')
                    }}
                    updateSearch={(_, updatedValue) => {
                      setTaskCreatedBy(updatedValue)
                    }}
                    showAllOnEmpty
                    showAllOnEmptyLabel="All"
                    isClearable={!!taskCreatedBy}
                  />
                )
              )}
            </div>

            {isEmpty(currentTab.tasks) ? (
              <NoData
                data-testid={`inbox-tasks-${currentTab.id}-empty`}
                headerText={currentTab.id === ARCHIVE ? 'Archive Empty' : 'Inbox Empty'}
                muted
                padded
              >
                {getEmptyState()}
              </NoData>
            ) : (
              <div className="inbox-tasks" data-testid={`inbox-tasks-${currentTab.id}`}>
                {currentTab.pagination.paginatedArray.map((task, index) => {
                  const description = [COACHING_INBOX, COMPLIANCE_INBOX].includes(inboxType)
                    ? task.evidence?.[0]?.ai_explanation || task.description
                    : task.description
                  const agentWithColor = agentsWithColors.find(
                    (agent) => agent.value === task.agent_id
                  )

                  return (
                    <TaskCard
                      index={index}
                      key={task.id}
                      id={getIdForTask(task)}
                      title={task.name}
                      isAiGenerated={task.ai_generated}
                      description={description}
                      callCount={task.call_count}
                      createdDate={task.created_at}
                      updatedDate={task.updated_at}
                      status={task.status}
                      agent={`${task.agent_first_name} ${task.agent_last_name}`}
                      color={agentWithColor?.color}
                      primaryActionButtonText={currentTab.primaryAction}
                      handlePrimaryAction={() => handleOpenTaskDrawer(task)}
                      secondaryActionButtonText={currentTab.secondaryAction}
                      handleSecondaryAction={
                        currentTab.id === ARCHIVE
                          ? () => handleUpdateTask(task.id, READY_FOR_REVIEW)
                          : () => handleUpdateTask(task.id, IGNORED)
                      }
                    />
                  )
                })}
                <AdvancedTablePagination
                  activePage={currentTab.pagination.activePage}
                  rowsPerPage={currentTab.pagination.rowsPerPage}
                  count={currentTab.pagination.count}
                  totalPages={currentTab.pagination.totalPages}
                  setActivePage={currentTab.pagination.setActivePage}
                  transparent
                />
              </div>
            )}
          </div>
        )}
      </div>
      <Task
        inboxType={inboxType}
        handleClose={handleCloseTaskDrawer}
        handleUpdateTask={handleUpdateTask}
      />
    </>
  )
}
