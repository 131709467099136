import * as SIDEBAR from './Sidebar.constants'

export const getTestId = (path) => {
  const removeSlash = path.split('/')
  removeSlash.shift()
  const testIdPath = removeSlash.join('-')

  return `${testIdPath}-link`
}

export const getAccess = (currentUser, currentOrganization, flags) => {
  const isBaltoAdmin = currentUser.organizationid === 1
  const isHierarchyManager = currentUser.hierarchy_manager
  const hasChildOrgSelected =
    isHierarchyManager && currentUser.organizationid !== currentUser.own_organization_id
  const hasRTCAccess =
    (currentUser.realtime_coaching_access || currentUser.real_time_management_access) &&
    currentUser.org_realtime_coaching_access &&
    !hasChildOrgSelected
  const hasCopilotAccess = currentOrganization.qa_copilot_enabled && currentUser.edit_qa_copilot
  const hasRTQAccess = currentOrganization.qa_enabled && currentUser.edit_qa
  const hasPlaybookEditAccess = currentUser.edit_config
  const hasNotetakerAccess = hasPlaybookEditAccess && currentOrganization.call_summarization_enabled
  const hasLiveListenAccess = !!flags.showCommandCenter
  const hasDynamicInsightsAccess = flags.dynamicInsightsEnabled
  const hasComplianceAccess = currentOrganization.compliance_tasks_enabled
  const hasCoachingAccess = currentOrganization.coaching_tasks_enabled

  return {
    isBaltoAdmin,
    isHierarchyManager,
    hasChildOrgSelected,
    hasRTCAccess,
    hasCopilotAccess,
    hasRTQAccess,
    hasNotetakerAccess,
    hasLiveListenAccess,
    hasDynamicInsightsAccess,
    hasComplianceAccess,
    hasCoachingAccess,
  }
}

const filterLinks = (links, access) => {
  const {
    isBaltoAdmin,
    isHierarchyManager,
    hasRTCAccess,
    hasCopilotAccess,
    hasRTQAccess,
    hasNotetakerAccess,
    hasLiveListenAccess,
    hasDynamicInsightsAccess,
    hasComplianceAccess,
    hasCoachingAccess,
  } = access

  const filteredLinks = links.filter((link) => {
    // Available to all users
    if (
      link.path.startsWith('/reports') ||
      link.path.startsWith('/playbooks') ||
      link.path.startsWith('/leaderboards') ||
      link.path.startsWith('/playlists') ||
      link.path.startsWith('/call-explorer')
    ) {
      return true
    }

    // QA Inbox
    if (link.path === '/qa-copilot/inbox') {
      return isBaltoAdmin || hasCopilotAccess || hasRTQAccess
    }

    // Balto Admin only
    if (
      link.path === '/organizations' ||
      link.path === '/insights' ||
      link.path === '/clear_transcripts_and_audio' ||
      link.path.startsWith('/coaching/scorecards')
    ) {
      return isBaltoAdmin
    }

    // Users only
    if (link.path === '/users') {
      return !isBaltoAdmin
    }

    if (link.path === '/command-center' || link.path === '/live-listen') {
      return isBaltoAdmin || hasLiveListenAccess
    }

    // RTC
    if (link.path.startsWith('/realtime_coaching')) {
      return isBaltoAdmin || hasRTCAccess
    }

    // Copilot
    if (link.path === '/qa-copilot/enterprise') {
      return isBaltoAdmin || isHierarchyManager
    }

    if (link.path.startsWith('/qa-copilot')) {
      return isBaltoAdmin || hasCopilotAccess
    }

    // RTQ
    if (link.path.startsWith('/scorecards')) {
      return isBaltoAdmin || hasRTQAccess
    }

    if (link.path.startsWith('/realtime-notetaker')) {
      return isBaltoAdmin || hasNotetakerAccess
    }

    if (link.path.startsWith('/dynamic-insights')) {
      return hasDynamicInsightsAccess
    }

    if (link.path.startsWith('/compliance')) {
      return hasComplianceAccess
    }

    if (link.path.startsWith('/coaching')) {
      return hasCoachingAccess
    }

    return isBaltoAdmin
  })

  return filteredLinks
}

export const getSidebarData = (currentOrganization, currentUser, flags) => {
  const access = getAccess(currentUser, currentOrganization, flags)
  const calls = filterLinks(SIDEBAR.callsLinks, access)
  const quality = filterLinks(SIDEBAR.qualityLinks, access)
  const guidance = filterLinks(SIDEBAR.guidanceLinks, access)
  const coaching = filterLinks(SIDEBAR.coachingLinks, access)
  const compliance = filterLinks(SIDEBAR.complianceLinks, access)
  const notes = filterLinks(SIDEBAR.notesLinks, access)
  const admin = filterLinks(SIDEBAR.adminLinks, access)
  const dataExport = filterLinks(SIDEBAR.dataExportLinks, access)
  const insights = filterLinks(SIDEBAR.insightsLinks, access)

  return {
    [SIDEBAR.CALLS]: { title: 'Calls', links: calls },
    [SIDEBAR.INSIGHTS]: { title: 'Insights', links: insights, noCollapse: true },
    [SIDEBAR.COACHING]: { title: 'Coaching', links: coaching, noCollapse: true },
    [SIDEBAR.COMPLIANCE]: { title: 'Compliance', links: compliance, noCollapse: true },
    [SIDEBAR.QUALITY]: { title: 'Quality', links: quality },
    [SIDEBAR.GUIDANCE]: { title: 'Guidance', links: guidance },
    [SIDEBAR.NOTES]: { title: 'Notes', links: notes, noCollapse: true },
    [SIDEBAR.ADMIN]: { title: 'Admin', links: admin },
    [SIDEBAR.DATA_EXPORT]: { title: 'Data Export', links: dataExport },
  }
}
